import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import {
  getCampaignDetailV1,
  getCampaignsCountV1,
  getBusinessCampaignsV1,
  publishVideoSettingsV1,
  uploadVideoImageV1,
  VerifyCampaignRequest,
  verifyCampaignV1,
  getInstitutionalCampaignsV1,
} from '../apis/videoApis';
import { actionFactory } from '../infrastructure/actionFactory';

export const getCampaignsCount = () =>
  actionFactory('ADMIN_VIDEO_CAMPAIGNS_COUNT_GET', getCampaignsCountV1());

export const getCampaignDetail = (campaignId: string) =>
  actionFactory(
    'ADMIN_VIDEO_CAMPAIGN_DETAIL_GET',
    getCampaignDetailV1(campaignId)
  );

export const getPagedBusinessCampaigns = (
  pageNumber: number,
  pageSize: number,
  state: number,
  cityId: string,
  userId: string,
  sortProperty?: string,
  sortOrder?: SortType
) =>
  actionFactory(
    'ADMIN_VIDEO_CAMPAIGNS_GET',
    getBusinessCampaignsV1(
      pageNumber,
      pageSize,
      state,
      cityId,
      userId,
      sortProperty,
      sortOrder
    )
  );

export const getPagedInstitutionalsCampaigns = (
  pageNumber: number,
  pageSize: number,
  state: number,
  cityId: string,
  userId: string,
  sortProperty?: string,
  sortOrder?: SortType
) =>
  actionFactory(
    'ADMIN_VIDEO_INSTITUTIONALS_GET',
    getInstitutionalCampaignsV1(
      pageNumber,
      pageSize,
      cityId,
      userId,
      state,
      sortProperty,
      sortOrder
    )
  );

export const publishVideoSettings = (cityId: string, publish: boolean) =>
  actionFactory(
    'CITY_VIDEO_SETTINGS_PUBLISH',
    publishVideoSettingsV1(cityId, publish)
  );

export const verifyCampaign = (request: VerifyCampaignRequest) =>
  actionFactory('VIDEO_CAMPAIGN_VERIFY', verifyCampaignV1(request));

export const uploadVideoImage = (campaignId: string, data: FormData) =>
  actionFactory('VIDEO_IMAGE_UPLOAD', uploadVideoImageV1(campaignId, data));
