import { Button } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useCallback } from 'react';
import { VideoTableCampaign } from '../../apis/videoApis';
import { useModalProvider } from '../../components';
import { colors } from '../../theming/theme';
import VerifyCampaignModal, {
  VerifyCampaignModalProps,
} from './VerifyCampaignModal';

export interface VerifyButtonProps {
  campaign: VideoTableCampaign;
  onReloadCampaigns: Function;
}

const ButtonStyled = styled(Button)({
  backgroundColor: colors.warning300,
  color: colors.text400,
  border: 'none',
  paddingLeft: '32px',
  paddingRight: '32px',
});

const VerifyButton: FC<VerifyButtonProps> = ({
  campaign,
  onReloadCampaigns,
}) => {
  const { openModal, closeModal } = useModalProvider();

  const handleCloseModal = useCallback(
    (reload: boolean) => {
      closeModal({
        identifier: 'VerifyCampaignModal',
      });

      if (reload) {
        onReloadCampaigns();
      }
    },
    [closeModal, onReloadCampaigns]
  );

  const handleClick = useCallback(() => {
    openModal<VerifyCampaignModalProps>({
      identifier: 'VerifyCampaignModal',
      //lazyImportFn: () => import('./VerifyCampaignModal'),
      component: VerifyCampaignModal,
      additionalProps: {
        campaign,
        onClose: handleCloseModal,
      },
    });
  }, [openModal, handleCloseModal, campaign]);

  return (
    <ButtonStyled variant="primary" size="sm" onClick={handleClick}>
      Verifica
    </ButtonStyled>
  );
};

export default VerifyButton;
