import { Box, Button, Flex, Input, Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useState, useRef } from 'react';
import { getLedwallUri } from '../../../infrastructure/environment';

export interface SiteLinkButtonProps {
  siteLink: string;
}

const CopyLinkButton = styled(Button)({
  paddingLeft: '1rem',
  paddingRight: '1rem',
});

const SiteLinkButton: FC<SiteLinkButtonProps> = ({ siteLink }) => {
  const [showLink, setShowLink] = useState<boolean>(false);
  const [clipboardCopy, setClipboardCopy] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>();

  const link = `${getLedwallUri()}/${siteLink}`;

  const handleLinkFocus = () => {
    if (!_.isNil(inputRef)) {
      inputRef.current?.select();
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setClipboardCopy(true);

    setTimeout(() => {
      setClipboardCopy(false);
    }, 1500);

    setTimeout(() => {
      setShowLink(false);
    }, 3000);
  };

  return showLink ? (
    <Flex justifyContent={'space-between'}>
      <Box mr="4px">
        <Input
          readOnly
          value={link}
          size="sm"
          onClick={handleLinkFocus}
          ref={inputRef}
        />
      </Box>
      <Box ml="4px">
        <Tooltip
          position="right"
          content={
            clipboardCopy ? (
              <span>Link copiato negli appunti</span>
            ) : (
              <span>Copia link negli appunti</span>
            )
          }
        >
          <CopyLinkButton
            variant="secondary"
            size="sm"
            isFullWidth
            onClick={handleCopyLink}
            icon={clipboardCopy ? 'clipboard-check' : 'clipboard'}
          />
        </Tooltip>
      </Box>
    </Flex>
  ) : (
    <Button
      variant="secondary"
      isFullWidth
      size="sm"
      icon="link"
      onClick={() => setShowLink(!showLink)}
    >
      Vedi link impianto
    </Button>
  );
};

export default SiteLinkButton;
