import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import Helmet from 'react-helmet';

export interface ApplicationProps {
  header?: ReactNode;
  footer?: ReactNode;
  showFooter?: boolean;
}

const HeaderStyled = styled.div({});

const BodyStyled = styled.div<ApplicationProps>(({ showFooter }) => ({
  minHeight: 'calc(100vh - 99px)',
  maxHeight: 'calc(100vh - 99px)',
  height: 'calc(100vh - 95px)',
  overflowY: 'auto',
  margin: '0 26px',
  ...(showFooter && {
    minHeight: 'calc(100vh - calc(95px + 78px))',
    maxHeight: 'calc(100vh - calc(95px + 78px))',
  }),
}));

const FooterStyled = styled.div<ApplicationProps>(({ showFooter }) => ({
  display: 'none',
  ...(showFooter && { display: 'block' }),
}));

const Application: FC<ApplicationProps> = ({ children, header, footer }) => (
  <>
    <Helmet titleTemplate="Publista - %s" defaultTitle="Home" />

    <div>
      <HeaderStyled>{header}</HeaderStyled>
      <BodyStyled>{children}</BodyStyled>
      <FooterStyled>{footer}</FooterStyled>
    </div>
  </>
);

export default Application;
