import { Box, Button, Flex } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useState, useMemo } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import { Badge, DefaultApplication } from '../../components';
import { CampaignState } from '../../enums';
import useStateCounters from '../../hooks/useStateCounters';
import CampaignsTable from './CampaignsTable';
import NoRequestsBanner from './NoRequestBanner';

const Campaigns: FC = () => {
  const [stateFilter, setStateFilter] = useState<number>(
    CampaignState.ToVerify
  );
  const { stateCounters } = useStateCounters();

  const toVerifyCount = useMemo(
    () => stateCounters.find((x) => x.state === CampaignState.ToVerify),
    [stateCounters]
  );

  const refusedCount = useMemo(
    () => stateCounters.find((x) => x.state === CampaignState.Refused),
    [stateCounters]
  );

  const anyResults = useMemo(
    () =>
      (!_.isNil(toVerifyCount) && toVerifyCount.count !== 0) ||
      (!_.isNil(refusedCount) && refusedCount.count !== 0),
    [toVerifyCount, refusedCount]
  );

  return (
    <DefaultApplication>
      <Helmet title="Richieste" />

      <Container fluid className="pa__campaigns">
        {anyResults ? (
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Flex mt="24px">
                <Box mr="16px">
                  <Button
                    variant={
                      stateFilter === CampaignState.ToVerify
                        ? 'primary'
                        : 'unstyled'
                    }
                    size="sm"
                    onClick={() => setStateFilter(CampaignState.ToVerify)}
                  >
                    {toVerifyCount?.count !== 0 && (
                      <Badge variant="warning" className="mr-4">
                        {toVerifyCount?.count || 0}
                      </Badge>
                    )}
                    In attesa
                  </Button>
                </Box>
                <Box mr="16px">
                  <Button
                    variant={
                      stateFilter === CampaignState.Refused
                        ? 'primary'
                        : 'unstyled'
                    }
                    size="sm"
                    onClick={() => setStateFilter(CampaignState.Refused)}
                  >
                    {refusedCount?.count !== 0 && (
                      <Badge variant="error" className="mr-4">
                        {refusedCount?.count || 0}
                      </Badge>
                    )}
                    Da risolvere
                  </Button>
                </Box>
              </Flex>
              <Box mt="24px">
                <CampaignsTable filteredState={stateFilter} />
              </Box>
            </Col>
          </Row>
        ) : (
          <Row className="mt-24">
            <NoRequestsBanner />
          </Row>
        )}
      </Container>
    </DefaultApplication>
  );
};

export default Campaigns;
