import { Box, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { colors } from '../../theming/theme';

export interface FooterProps {
  className?: string;
  leftContent?: ReactNode;
  middleContent?: ReactNode;
  rightContent?: ReactNode;
}

const FooterContainer = styled(Flex)({
  backgroundColor: colors.white,
  borderTop: 'solid 1px ' + colors.grey300,
  padding: '16px 42px',
});

const LeftFooter = styled(Box)();
const MiddleFooter = styled(Box)({
  flexGrow: '4',
  flexShrink: '3',
});
const RightFooter = styled(Box)();

const Footer: FC<FooterProps> = ({
  className,
  leftContent,
  middleContent,
  rightContent,
  ...rest
}) => (
  <FooterContainer {...rest}>
    <LeftFooter>{leftContent}</LeftFooter>
    <MiddleFooter>{middleContent}</MiddleFooter>
    <RightFooter>{rightContent}</RightFooter>
  </FooterContainer>
);

export default Footer;
