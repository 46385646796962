import {
  deleteDefaultInstitutionalImage,
  getDefaultInstitutionalImage,
  uploadDefaultInstitutionalImage,
} from '../actions';
import { useAction } from './useAction';

export const useDefaultInstitutionalImage = () => {
  const getDefaultInstitutionalImg = useAction(getDefaultInstitutionalImage);
  const uploadDefaultInstitutionalImg = useAction(
    uploadDefaultInstitutionalImage
  );
  const deleteDefaultInstitutionalImg = useAction(
    deleteDefaultInstitutionalImage
  );

  return {
    getDefaultInstitutionalImg,
    uploadDefaultInstitutionalImg,
    deleteDefaultInstitutionalImg,
  };
};
