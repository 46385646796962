import _ from 'lodash';
import { FC, useEffect, useState, useCallback } from 'react';
import {
  HeaderCell,
  useAdvancedTable,
  TableBody,
  TableHeader,
  AdvancedTable,
} from '@alexpireddu/map-ui';
import { useAction } from '../../hooks';
import FeebackRow from './FeedbackRow';
import NoResultsRow from './NoResultsRow';
import { FeedbackPaginated } from '../../apis/feedbackApis';
import { getFeedbacks as getAllFeedbacks } from '../../actions';

const FeedbacksTable: FC = () => {
  const [feedbackList, setFeedbackList] = useState<FeedbackPaginated>();
  const getFeedbacksList = useAction(getAllFeedbacks);
  const advancedTableProps = useAdvancedTable({
    sortProperty: 'date',
    sortOrder: 'desc',
    totalCount: feedbackList?.total || 0,
    pageSize: 5,
  });

  const { currentPage, pageSize, sortProperty, sortOrder } = advancedTableProps;

  const getFeedbacks = useCallback(() => {
    getFeedbacksList(currentPage, pageSize, sortProperty, sortOrder).then(
      (resp: any) => setFeedbackList(resp.value.data)
    );
  }, [getFeedbacksList, currentPage, pageSize, sortProperty, sortOrder]);

  useEffect(() => {
    getFeedbacks();
  }, [getFeedbacks]);

  return (
    <AdvancedTable isFullWidth {...advancedTableProps}>
      <TableHeader>
        <tr>
          <HeaderCell bindingProperty="date" fixedWidth={180}>
            Data inserimento
          </HeaderCell>
          <HeaderCell bindingProperty="feedback" fixedWidth={160}>
            Feedback
          </HeaderCell>
          <HeaderCell bindingProperty="notes">Contenuto</HeaderCell>
          <HeaderCell bindingProperty="section">Sezione</HeaderCell>
          <HeaderCell bindingProperty="user">Utente</HeaderCell>
          <HeaderCell bindingProperty="city">Comune</HeaderCell>
        </tr>
      </TableHeader>
      <TableBody>
        {_.map(feedbackList?.results, (f) => (
          <FeebackRow key={f.id} feedback={f} />
        ))}
        {!_.some(feedbackList?.results) && <NoResultsRow />}
      </TableBody>
    </AdvancedTable>
  );
};

export default FeedbacksTable;
