import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import {
  AccessDeniedPage,
  CheckAuthorization,
  DefaultErrorPage,
  ErrorBoundary,
  NotFoundErrorPage,
} from '../components';
import Bootstrapper from '../components/Bootstrapper';
import { NotificationContainer } from '@alexpireddu/map-ui';
import Cities from '../areas/Cities';
import Feedbacks from '../areas/Feedbacks';
import CityConfiguration from '../areas/CityConfiguration';
import Support from '../areas/Support';
import Requests from '../areas/Requests';
import Overview from '../areas/CityConfiguration/Overview';
import Sites from '../areas/CityConfiguration/Sites';
import Employees from '../areas/CityConfiguration/Employees';
import Pubblication from '../areas/CityConfiguration/Pubblication';
import Defaults from '../areas/CityConfiguration/Defaults';
import CampaignsHistory from '../areas/Campaigns';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <Bootstrapper>
          <NotificationContainer />
          <Outlet />
        </Bootstrapper>
      </ErrorBoundary>
    ),
    errorElement: <DefaultErrorPage />,
    children: [
      {
        path: 'unauthorized',
        element: <AccessDeniedPage />,
      },
      {
        path: '/',
        element: <CheckAuthorization />,
        children: [
          {
            path: '*',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Navigate to="comuni" />,
              },
              {
                path: 'comuni',
                element: <Cities />,
              },
              {
                path: 'richieste',
                element: <Requests />,
              },
              {
                path: 'campagne',
                element: <CampaignsHistory />,
              },
              {
                path: 'feedbacks',
                element: <Feedbacks />,
              },
              {
                path: 'configura/:currentCityId/*',
                element: <CityConfiguration />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="generale" />,
                  },
                  {
                    path: 'generale',
                    element: <Overview />,
                  },
                  {
                    path: 'impianti',
                    element: <Sites />,
                  },
                  {
                    path: 'collaboratori',
                    element: <Employees />,
                  },
                  {
                    path: 'default-media',
                    element: <Defaults />,
                  },
                  {
                    path: 'pubblicazione',
                    element: <Pubblication />,
                  },
                ],
              },
              {
                path: 'support',
                element: <Support />,
              },
              {
                path: '*',
                element: <NotFoundErrorPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
