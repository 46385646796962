import { Box } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Application, Header, Logo, UserMenu, NewCityButton } from '..';
import RoleName from './RoleName';
import Divider from './Divider';
import Navigation from './Navigation';
import { useAppSelector } from '../../hooks';

export interface DashboardContainerProps {
  browserTitle?: string;
}

const DefaultApplication: FC<DashboardContainerProps> = ({
  children,
  browserTitle,
}) => {
  const { user } = useAppSelector((store) => ({
    user: store.user,
  }));
  const navigate = useNavigate();

  return (
    <>
      {!_.isNil(browserTitle) && <Helmet title={browserTitle} />}

      <Application
        header={
          <Header
            leftContent={
              <>
                <Box>
                  <Logo clickable onClick={() => navigate('/')} />
                </Box>
                <Box pr={24} pl={40}>
                  <Divider />
                </Box>
                <Box>
                  <RoleName />
                </Box>
              </>
            }
            rightContent={
              <>
                <Navigation />
                <Box mr="24px" ml="32px">
                  <NewCityButton />
                </Box>
                <Box>
                  <UserMenu name={user.data.name + ' ' + user.data.surname} />
                </Box>
              </>
            }
          />
        }
      >
        {children}
      </Application>
    </>
  );
};

export default DefaultApplication;
