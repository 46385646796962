import { Card } from '@alexpireddu/map-ui';
import { FC } from 'react';
import UserBox from './UserBox';
import styled from '@emotion/styled';
import { colors } from '../../theming/theme';
import MenuLinks from './MenuLinks';
import { useAppSelector } from '../../hooks';

const ContentContainer = styled(Card)({
  maxWidth: '264px',
  width: '264px',
});

const Divider = styled.div({
  marginLeft: '-24px',
  marginRight: '-24px',
  borderBottom: 'solid 1px ' + colors.grey300,
  marginTop: '24px',
  marginBottom: '24px',
});

const Content: FC = () => {
  const { user } = useAppSelector((store) => ({
    user: store.user.data,
  }));

  return (
    <ContentContainer size="lg" className="mt-8 mr-0">
      <UserBox user={user} />
      <Divider />
      <MenuLinks />
    </ContentContainer>
  );
};

export default Content;
