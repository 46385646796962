import { Alert, Box, Button, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CampaignStateCount } from '../../apis/videoApis';
import useStateCounters from '../../hooks/useStateCounters';
import { colors, shadows } from '../../theming/theme';

const BannerAlert = styled(Alert)({
  backgroundColor: colors.warning300,
  textAlign: 'center',
  fontSize: '24px',
  padding: '16px 32px',
  color: colors.text400,
  fontWeight: '700',
  boxShadow: shadows.base,
  marginBottom: '24px',
});

const BannerButton = styled(Button)({
  backgroundColor: colors.warning300,
  borderWidth: '2px',
});

const evaluatePluralMessage = (count: number) => {
  if (count === 1) {
    return 'richiesta';
  }

  return 'richieste';
};

const PendingRequestsBanner: FC = () => {
  const navigate = useNavigate();

  const { stateCounters } = useStateCounters();

  const stateCount = useMemo(
    () => _.sum(stateCounters.map((x: CampaignStateCount) => x.count)),
    [stateCounters]
  );

  if (stateCount === 0) {
    return null;
  }

  return (
    <BannerAlert>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <span>
            Hai {stateCount} {evaluatePluralMessage(stateCount)} in attesa.
          </span>
        </Box>
        <Box>
          <BannerButton
            variant="secondary"
            size="lg"
            onClick={() => navigate('/richieste')}
          >
            Vedi le richieste
          </BannerButton>
        </Box>
      </Flex>
    </BannerAlert>
  );
};

export default PendingRequestsBanner;
