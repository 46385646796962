import { FC } from 'react';
import { Alert } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { colors, shadows } from '../../theming/theme';

const BannerAlert = styled(Alert)({
  backgroundColor: colors.neutral100,
  textAlign: 'center',
  fontSize: '24px',
  padding: '32px',
  color: colors.neutral500,
  fontWeight: '700',
  boxShadow: shadows.base,
});

const NoRequestsBanner: FC = () => <BannerAlert>Nessuna richiesta</BannerAlert>;

export default NoRequestsBanner;
