import { Reducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import typeToReducer from 'type-to-reducer';
import { PublistaAction } from '../infrastructure/commonTypes';
import {
  videoInitialState,
  VideoInitialStateType,
} from '../store/initialState';
import { parseAxiosError } from '../utils/axiosResponseHelper';

export const videoReducer: Reducer<VideoInitialStateType, PublistaAction> =
  typeToReducer<VideoInitialStateType, PublistaAction>(
    {
      ADMIN_VIDEO_CAMPAIGNS_COUNT_GET: {
        PENDING: (state) =>
          update(state, {
            stateCounters: {
              fetching: { $set: true },
            },
          }),
        REJECTED: (state, action) => {
          const payload = action?.payload;

          return update(state, {
            stateCounters: {
              fetching: { $set: false },
              fetched: { $set: false },
              error: { $set: parseAxiosError(payload) },
            },
          });
        },
        FULFILLED: (state, action) => {
          const payload = action?.payload;

          return update(state, {
            stateCounters: {
              fetching: { $set: false },
              fetched: { $set: true },
              data: { $set: payload.data },
              error: { $set: null },
            },
          });
        },
      },
    },
    videoInitialState
  );
