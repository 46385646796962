import {
  AdvancedTable,
  HeaderCell,
  TableBody,
  TableHeader,
  useAdvancedTable,
} from '@alexpireddu/map-ui';
import { FC, useState, useCallback, useEffect } from 'react';
import { VideoCampaignListResponse } from '../../apis/videoApis';
import _ from 'lodash';
import InstitutionalsTableRow from './InstitutionalsTableRow';
import { getPagedInstitutionalsCampaigns } from '../../actions';
import { useAction } from '../../hooks';

export interface InstitutionalsTableProps {
  filteredUser: string;
  filteredCity: string;
  filteredState: number;
}

const InstitutionalsTable: FC<InstitutionalsTableProps> = ({
  filteredCity,
  filteredState,
  filteredUser,
}) => {
  const [campaignList, setCampaignList] = useState<VideoCampaignListResponse>();
  const getCampaigns = useAction(getPagedInstitutionalsCampaigns);

  const advancedTableProps = useAdvancedTable({
    sortProperty: 'state_at',
    sortOrder: 'desc',
    totalCount: campaignList?.total || 0,
    pageSize: 5,
    pageSizes: [5, 10, 20, 50, 100],
  });

  const { currentPage, pageSize, sortProperty, sortOrder } = advancedTableProps;

  const loadCampaigns = useCallback(() => {
    getCampaigns(
      currentPage,
      pageSize,
      filteredState,
      filteredCity,
      filteredUser,
      sortProperty,
      sortOrder
    ).then((res: any) => setCampaignList(res.value.data));
  }, [
    currentPage,
    pageSize,
    sortProperty,
    sortOrder,
    getCampaigns,
    filteredCity,
    filteredUser,
    filteredState,
  ]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  return (
    <AdvancedTable isFullWidth {...advancedTableProps}>
      <TableHeader>
        <tr>
          <HeaderCell fixedWidth={130}>Contenuto</HeaderCell>
          <HeaderCell bindingProperty="from_date">Data inizio</HeaderCell>
          <HeaderCell bindingProperty="to_date">Data fine</HeaderCell>
          <HeaderCell>Durata</HeaderCell>
          <HeaderCell bindingProperty="name">Nome campagna</HeaderCell>
          <HeaderCell>Comune</HeaderCell>
          <HeaderCell>Richiedente</HeaderCell>
          <HeaderCell fixedWidth={180} bindingProperty="reserved_at">
            Prenotazione
          </HeaderCell>
          <HeaderCell bindingProperty="state" fixedWidth={140}>
            Stato
          </HeaderCell>
        </tr>
      </TableHeader>
      <TableBody>
        {_.map(campaignList?.results, (c) => (
          <InstitutionalsTableRow key={c.id} campaign={c} />
        ))}
      </TableBody>
    </AdvancedTable>
  );
};

export default InstitutionalsTable;
