import { get } from '../infrastructure/webRequest';

export interface SiteCategory {
  id: string;
  name: string;
}

export type SiteCategories = Array<SiteCategory>;

export interface Region {
  id: string;
  name: string;
}

export type Regions = Array<Region>;

export interface Province {
  code: string;
  id: string;
  name: string;
  region_id: string;
}

export type Provinces = Array<Province>;

export interface SiteFormat {
  height: number;
  id: string;
  sheet_number: number;
  width: number;
}

export type SiteFormats = Array<SiteFormat>;

export const getSiteCategoriesV1 = () =>
  get<SiteCategories>('/v1/admin/advertising/sites/categories');

export const getProvinceInfoV1 = (id: string) =>
  get<Province>('/v1/province/info', { id });

export const getProvincesByRegionV1 = (regionId: string) =>
  get<Provinces>('/v1/provinces', {
    region_id: regionId,
  });
export const getRegionsV1 = () => get<Regions>('/v1/regions');

export const getFormatsV1 = () => get<SiteFormats>('/v1/admin/video/formats');
