import styled from '@emotion/styled';
import { FC } from 'react';
import { useCityLogo } from '../../hooks';
import placeholder from '../../assets/image-placeholder-w35.jpg';
import _ from 'lodash';
import { colors } from '../../theming/theme';

export interface CityLogoProps {
  cityId: string;
  header?: boolean;
}

const CityImage = styled.img<{ header: boolean }>(({ header = false }) => ({
  height: header ? '35px' : '60px',
  width: header ? '35px' : '60px',
  margin: '0',
  objectFit: 'contain',
  aspectRatio: '4 / 4',
  borderRadius: '50%',
  ...(header && {
    filter: 'grayscale(1)',
  }),
  ...(!header && {
    border: 'solid 1px ' + colors.text300,
    padding: '3px',
  }),
}));

const CityLogo: FC<CityLogoProps> = ({
  cityId,
  header = false,
}: CityLogoProps) => {
  const uri = useCityLogo(cityId);

  return (
    <CityImage
      alt="Logo"
      src={_.isNil(uri) ? placeholder : uri}
      title="Logo"
      header={header}
    />
  );
};

export default CityLogo;
