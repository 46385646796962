import { Button, Tooltip } from '@alexpireddu/map-ui';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { setSelectedCity } from '../../actions';
import { CityTableRow } from '../../apis/cityApis';
import { Badge, EditIcon, SettingsIcon } from '../../components';
import { useAction } from '../../hooks';
import { colors } from '../../theming/theme';

export interface TableRowProps {
  city: CityTableRow;
  lastToConfigure?: boolean;
}

const applyConfigureStyle = (last?: boolean) => {
  if (last) {
    return {
      style: {
        borderBottom: 'solid 3px ' + colors.grey300,
      },
    };
  }
};

const TableRow: FC<TableRowProps> = ({ city, lastToConfigure }) => {
  const navigate = useNavigate();
  const selectCity = useAction(setSelectedCity);

  const handleCitySelection = useCallback(() => {
    selectCity(city);
    navigate('/configura/' + city.id + '/');
  }, [selectCity, navigate, city]);

  return (
    <tr>
      <td {...applyConfigureStyle(lastToConfigure)}>
        <Tooltip content={<span>Configura</span>}>
          <Button
            variant="unstyled"
            size="sm"
            mr="8px"
            onClick={handleCitySelection}
          >
            {city.services.video ? <SettingsIcon /> : <EditIcon />}
          </Button>
        </Tooltip>
        <Badge variant={city.services.video ? 'success' : 'neutral'} />
      </td>
      <td {...applyConfigureStyle(lastToConfigure)}>{city.name}</td>
      <td {...applyConfigureStyle(lastToConfigure)}>{city.province.name}</td>
      <td {...applyConfigureStyle(lastToConfigure)}>{city.in_progress}</td>
      <td {...applyConfigureStyle(lastToConfigure)}>{city.approved}</td>
      <td {...applyConfigureStyle(lastToConfigure)}>{city.completed}</td>
      <td {...applyConfigureStyle(lastToConfigure)} />
    </tr>
  );
};

export default TableRow;
