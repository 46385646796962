import styled from '@emotion/styled';
import { space, SpaceProps } from 'styled-system';

export type BulletVariant =
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | 'neutral';

const convertVariantToThemeColor = (theme: any, variant: BulletVariant) => {
  switch (variant) {
    case 'neutral':
      return theme.colors.neutral300;
    case 'info':
      return theme.colors.interaction200;
    case 'error':
      return theme.colors.danger300;
    case 'warning':
      return theme.colors.warning300;
    case 'success':
      return theme.colors.success300;
  }
};

export type BulletProps = SpaceProps & {
  variant?: BulletVariant;
  size?: number | string;
};

const Bullet = styled.span<BulletProps>(
  ({ theme, variant = 'neutral', size = 8 }) => ({
    borderRadius: '50%',
    width: size,
    height: size,
    display: 'inline-block',
    backgroundColor: convertVariantToThemeColor(theme, variant),
  }),
  space
);

export default Bullet;
