import { Input } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { colors } from '../../../theming/theme';

const PriceInput = styled(Input)({
  border: 'none',
  fontWeight: 700,
  fontSize: '3rem',
  textAlign: 'center',
  color: colors.interaction300,
});

export default PriceInput;
