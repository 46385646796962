import { DropDown } from '@alexpireddu/map-ui';
import { FC, useState } from 'react';
import Content from './Content';
import { Trigger } from './Trigger';

export interface UserMenuProps {
  name: string;
}

export const UserMenu: FC<UserMenuProps> = ({ name }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <DropDown
      open={menuOpen}
      onClick={() => setMenuOpen(!menuOpen)}
      onClickOutside={() => setMenuOpen(false)}
      trigger={<Trigger name={name} />}
      content={<Content />}
    />
  );
};
