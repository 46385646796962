import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import { getFeedbacksV1 } from '../apis';
import { actionFactory } from '../infrastructure/actionFactory';

export const getFeedbacks = (
  pageNumber: number,
  pageSize: number,
  sortProperty?: string,
  sortOrder?: SortType,
  fromDate?: string,
  toDate?: string,
  cityId?: string,
  feedback?: string,
  section?: string
) =>
  actionFactory(
    'FEEDBACKS_GET',
    getFeedbacksV1(
      pageNumber,
      pageSize,
      sortProperty,
      sortOrder,
      fromDate,
      toDate,
      cityId,
      feedback,
      section
    )
  );
