import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useMemo } from 'react';
import { CampaignState } from '../../enums';
import { useCityParam } from '../../hooks';
import useStateCounters from '../../hooks/useStateCounters';
import Badge from '../Badge';
import { SettingsIcon } from '../Icons';
import { NavigationItem } from './NavigationItem';

const NavItemBadge = styled(Badge)({
  position: 'relative',
  top: '-10px',
});

const Navigation: FC = () => {
  const { currentCityId } = useCityParam();
  const { stateCounters } = useStateCounters();

  const toVerifyCount = useMemo(
    () => stateCounters.find((x) => x.state === CampaignState.ToVerify),
    [stateCounters]
  );

  const refusedCount = useMemo(
    () => stateCounters.find((x) => x.state === CampaignState.Refused),
    [stateCounters]
  );

  return _.isNil(currentCityId) ? (
    <>
      <NavigationItem link="/comuni" label="Comuni" />
      <NavigationItem
        link="/richieste"
        label={
          <span>
            Richieste
            {toVerifyCount?.count! > 0 && (
              <NavItemBadge variant="warning" className="ml-4">
                {toVerifyCount?.count}
              </NavItemBadge>
            )}
            {refusedCount?.count! > 0 && (
              <NavItemBadge variant="error" className="ml-4">
                {refusedCount?.count}
              </NavItemBadge>
            )}
          </span>
        }
      />
      <NavigationItem link="/campagne" label="Campagne" />
      <NavigationItem link="/feedbacks" label="Feedbacks" />
    </>
  ) : (
    <>
      <NavigationItem link="/comuni" label="Comuni" />
      <NavigationItem link="/feedbacks" label="Feedbacks" />
      <NavigationItem
        link={'/configura/' + currentCityId}
        label={<SettingsIcon />}
      />
    </>
  );
};

export default Navigation;
