import { Box, Text } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import DefaultCommercial from './Commercial';
import DefaultInstitutional from './Institutional';

const Defaults: FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box ml={12} mt={12} mb={24}>
            <Text as="h2">Default media</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box mb={24}>
            <DefaultCommercial />
          </Box>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box mb={24}>
            <DefaultInstitutional />
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export default Defaults;
