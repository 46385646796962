import { FC } from 'react';
import styled from '@emotion/styled';

import support from '../../assets/assistenza.png';
import { Text } from '@alexpireddu/map-ui';
import { Stack } from '@alexpireddu/map-ui/dist/components/Stack';
import { DefaultApplication } from '../../components';
import { Helmet } from 'react-helmet';
import { Container } from 'react-grid-system';

const Image = styled.img({
  maxWidth: 335,
  width: '100%',
  aspectRatio: '1 / 1',
});

const Link = styled.a({
  fontStyle: 'normal',
  color: '#0094ca',
  textDecoration: 'underline',
});

const Support: FC = () => (
  <DefaultApplication>
    <Helmet title="Assistenza" />

    <Container fluid className="pa__support">
      <Stack spacing={4} width="100%" alignItems="center" paddingTop={16}>
        <div>
          <Text as="h2" textAlign="center">
            Assistenza
          </Text>
          <Text as="h3" textAlign="center" fontWeight={400}>
            Hai bisogno dell’aiuto di Publista?
          </Text>
        </div>
        <Image src={support} alt="support" />
        <Stack spacing={2} alignItems="center">
          <Text as="h3" fontWeight={400}>
            Contattaci:
          </Text>
          <Stack as="address" spacing={1} alignItems="center">
            <Link href="mailto:info@publista.it">info@publista.it</Link>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  </DefaultApplication>
);

export default Support;
