import { Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { colors } from '../../theming/theme';

export type DailySlotType = 'M' | 'P' | 'S' | undefined;

const evalutateDailySlot = (dailySlot: number): DailySlotType => {
  switch (dailySlot) {
    case 1:
      return 'M';
    case 2:
      return 'P';
    case 3:
      return 'S';
  }
};

const parseInlineHelp = (dailySlot: number) => {
  switch (dailySlot) {
    case 1:
      return 'Mattino: 6.00 - 11.59';
    case 2:
      return 'Pomeriggio: 12.00 - 17.59';
    case 3:
      return 'Sera: 18.00 - 23.59';
  }
};

const Rect = styled.span({
  borderRadius: 4,
  color: 'white',
  backgroundColor: colors.text300,
  height: 22,
  width: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '8px',
});

export interface DailySlotProps {
  dailySlot: number;
}

const DailySlot: FC<DailySlotProps> = ({ dailySlot }) => {
  const slot = evalutateDailySlot(dailySlot);

  return (
    <Tooltip content={<span>{parseInlineHelp(dailySlot)}</span>}>
      <Rect>{slot}</Rect>
    </Tooltip>
  );
};

export default DailySlot;
