import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Box } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../theming/theme';

export interface NavigationItemProps {
  link: string;
  label: string | ReactNode;
  active?: boolean;
}

const NavLink = styled(Link)({
  ':active': {
    color: colors.text300,
  },
  ':visited': {
    color: colors.text300,
  },
});

const NavItemContainer = styled(Box)<{ active: boolean }>(({ active }) => ({
  padding: '38px 18px 27px 18px',
  margin: '0 8px',
  boxSizing: 'content-box',
  borderBottom: 'solid 3px transparent',
  ...(active && {
    fontWeight: 'bold',
    borderBottom: 'solid 3px ' + colors.text400,
  }),
}));

export const NavigationItem: FC<NavigationItemProps> = ({
  link,
  label,
  active = false,
}) => {
  const { pathname } = useLocation();

  return (
    <NavItemContainer active={_.startsWith(pathname, link) || active}>
      <NavLink to={link}>{label}</NavLink>
    </NavItemContainer>
  );
};
