import { FC, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { DefaultApplication } from '../../components';
import styled from '@emotion/styled';
import { Box, Flex, FormControl, Label, Select } from '@alexpireddu/map-ui';
import { CampaignPurpose, CampaignState } from '../../enums';
import { BaseUserInfo } from '../../apis/userApis';
import { useAction } from '../../hooks';
import { getCities, getUsers } from '../../actions';
import _ from 'lodash';
import { Cities } from '../../apis/cityApis';
import CampaignsTable from './CampaignsTable';
import InstitutionalsTable from './InstitutionalsTable';

const FilterSelect = styled(Select)({
  minWidth: 200,
});

const CampaignsHistory: FC = () => {
  const [purposefilter, setPurposeFilter] = useState<number>(
    CampaignPurpose.Business
  );
  const [userFilter, setUserFilter] = useState<string>('');
  const [stateFilter, setStateFilter] = useState<number>(CampaignState.All);
  const [cityFilter, setCityFilter] = useState<string>('');
  const [usersList, setUsersList] = useState<Array<BaseUserInfo>>([]);
  const [citiesList, setCitiesList] = useState<Cities>([]);

  const getAllUser = useAction(getUsers);
  const getAllCities = useAction(getCities);

  useEffect(() => {
    getAllUser().then((res: any) => {
      setUsersList(res.value.data);
    });

    getAllCities().then((res: any) => {
      setCitiesList(res.value.data);
    });
  }, [getAllUser, getAllCities]);

  return (
    <DefaultApplication browserTitle="Campagne">
      <Container fluid>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Flex mt="24px">
              <Box mr="16px">
                <FormControl>
                  <Label>Filtra per tipo campagna</Label>
                  <FilterSelect
                    size="sm"
                    value={purposefilter}
                    onChange={(val) => setPurposeFilter(val as number)}
                    options={[
                      {
                        label: 'Commerciali',
                        value: CampaignPurpose.Business,
                      },
                      {
                        label: 'Istituzionali',
                        value: CampaignPurpose.Istitutional,
                      },
                    ]}
                  ></FilterSelect>
                </FormControl>
              </Box>
              <Box mr="16px">
                <FormControl>
                  <Label>Filtra per richiedente</Label>
                  <FilterSelect
                    size="sm"
                    onChange={(val) => setUserFilter(val as string)}
                    value={userFilter}
                    options={[
                      {
                        label: 'Tutti',
                        value: '',
                      },
                      ..._.map(usersList, (x) => ({
                        label: `${x.name} ${x.surname}`,
                        value: x.id!,
                      })),
                    ]}
                  ></FilterSelect>
                </FormControl>
              </Box>
              <Box mr="16px">
                <FormControl>
                  <Label>Filtra per comune</Label>
                  <FilterSelect
                    size="sm"
                    value={cityFilter}
                    onChange={(val) => setCityFilter(val as string)}
                    options={[
                      {
                        label: 'Tutti',
                        value: '',
                      },
                      ..._.map(citiesList, (x) => ({
                        label: x.name,
                        value: x.id!,
                      })),
                    ]}
                  ></FilterSelect>
                </FormControl>
              </Box>
              <Box mr="16px">
                <FormControl>
                  <Label>Filtra per stato</Label>
                  <FilterSelect
                    size="sm"
                    onChange={(val) => setStateFilter(val as number)}
                    value={stateFilter}
                    options={[
                      {
                        label: 'Tutte',
                        value: CampaignState.All,
                      },
                      {
                        label: 'Approvate',
                        value: CampaignState.Approved,
                      },
                      {
                        label: 'Completate',
                        value: CampaignState.Completed,
                      },
                      {
                        label: 'Attive',
                        value: CampaignState.InProgress,
                      },
                      {
                        label: 'Rifiutate',
                        value: CampaignState.Refused,
                      },
                      {
                        label: 'Da approvare',
                        value: CampaignState.ToVerify,
                      },
                    ]}
                  ></FilterSelect>
                </FormControl>
              </Box>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box mt={24}>
              {purposefilter === CampaignPurpose.Business && (
                <CampaignsTable
                  filteredCity={cityFilter}
                  filteredState={stateFilter}
                  filteredUser={userFilter}
                />
              )}
              {purposefilter === CampaignPurpose.Istitutional && (
                <InstitutionalsTable
                  filteredCity={cityFilter}
                  filteredState={stateFilter}
                  filteredUser={userFilter}
                />
              )}
            </Box>
          </Col>
        </Row>
      </Container>
    </DefaultApplication>
  );
};

export default CampaignsHistory;
