import { get, post, put, remove } from '../infrastructure/webRequest';

export type CitySite = {
  active: boolean;
  created_at: string;
  format: {
    height: number;
    id: string;
    width: number;
  };
  id: string;
  latitude: string;
  longitude: string;
  name: string;
  price: number;
};

export type CitySites = Array<CitySite>;

export const getSitesV1 = (cityId: string) =>
  get<CitySites>(`/v1/admin/city/${cityId}/video/sites`);

export type CreateCitySiteRequest = {
  format_id: string;
  latitude: string;
  longitude: string;
  name: string;
  price: number;
};

export const createSiteV1 = (cityId: string, request: CreateCitySiteRequest) =>
  post(`/v1/admin/city/${cityId}/video/site/create`, request);

export type UpdateCitySiteRequest = CreateCitySiteRequest & {
  active: boolean;
  id: string;
};

export const updateSiteV1 = (cityId: string, request: UpdateCitySiteRequest) =>
  put(`/v1/admin/city/${cityId}/video/site/update`, request);

export const deleteSiteV1 = (cityId: string, id: string) =>
  remove(`/v1/admin/city/${cityId}/video/site/delete`, { data: { id } });

export const deleteDefaultCampaignImageV1 = (siteId: string) =>
  remove(`/v1/admin/video/campaigns/default?site_id=${siteId}`);

export const getDefaultCampaignImageV1 = (siteId: string) =>
  get<string>(
    `/v1/admin/video/campaigns/default?site_id=${siteId}`,
    undefined,
    {
      responseType: 'arraybuffer',
      responseEncoding: 'base64',
    }
  ).then((resp) => new Blob([resp.data]));

export const uploadDefaultCampaignImageV1 = (data: FormData) =>
  put(`/v1/admin/video/campaigns/default`, data);
