import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { useState, FC, useCallback } from 'react';
import { verifyCampaign } from '../../actions';
import { VideoTableCampaign } from '../../apis/videoApis';
import { useAction, useOriginalImage } from '../../hooks';
import placeholder from '../../assets/image-placeholder-w720.jpg';
import _ from 'lodash';

export interface VerifyCampaignModalProps {
  campaign: VideoTableCampaign;
  onClose: Function;
  readonly?: boolean;
}

const UppercaseButton = styled(Button)({
  textTransform: 'uppercase',
});

const CustomFooter = styled(ModalFooter)({
  justifyContent: 'space-around',
});

const PreviewImage = styled.img({
  maxWidth: '720px',
  padding: '0',
  margin: '0',
  aspectRatio: '16 / 9',
  objectFit: 'contain',
});

interface PreviewProps {
  imageId: string;
}

const Preview: FC<PreviewProps> = ({ imageId }: PreviewProps) => {
  const uri = useOriginalImage(imageId);

  return (
    <PreviewImage
      alt="Contenuto campagna"
      src={_.isNil(uri) ? placeholder : uri}
      title="Contenuto campagna"
    />
  );
};

const VerifyCampaignModal: FC<VerifyCampaignModalProps> = ({
  onClose,
  campaign,
  readonly = false,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const verify = useAction(verifyCampaign);

  const approveCampaign = useCallback(() => {
    setLoading(true);
    verify({
      approve: true,
      campaign_id: campaign.id,
    })
      .then(() => {
        setLoading(false);
        onClose(true);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [campaign.id, onClose, verify]);

  const refuseCampaign = useCallback(() => {
    setLoading(true);
    verify({
      approve: false,
      campaign_id: campaign.id,
    })
      .then(() => {
        setLoading(false);
        onClose(true);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [campaign.id, onClose, verify]);

  const handleClickOutside = () => {
    if (readonly) {
      onClose();
    }
  };

  return (
    <Modal size="xl" onClickOutside={handleClickOutside}>
      <ModalHeader>
        <ModalCloseButton onClick={() => onClose()} />
      </ModalHeader>
      <ModalBody>
        <Preview imageId={campaign.image.id} />
      </ModalBody>
      {!readonly && (
        <CustomFooter>
          <UppercaseButton
            variant="success"
            icon="check"
            mr="8px"
            onClick={approveCampaign}
            disabled={isLoading}
          >
            Accetta
          </UppercaseButton>
          <UppercaseButton
            variant="error"
            icon="times"
            ml="8px"
            onClick={refuseCampaign}
            disabled={isLoading}
          >
            Respingi
          </UppercaseButton>
        </CustomFooter>
      )}
    </Modal>
  );
};

export default VerifyCampaignModal;
