import { Stack, Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { FeedbackUser } from '../../apis/feedbackApis';
import { colors } from '../../theming/theme';

const MailLink = styled.a({
  textDecoration: 'underline',
  color: colors.interaction300,
});

export interface UserInfoTooltipProps {
  user: FeedbackUser;
}

const UserInfoTooltip: FC<UserInfoTooltipProps> = ({ user, children }) => (
  <Tooltip
    content={
      <span>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <span>Utente:</span>
            <span>
              {user.name} {user.surname}
            </span>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <span>Mail:</span>
            <MailLink href={`mailto:${user.email}`}>{user.email}</MailLink>
          </Stack>
        </Stack>
      </span>
    }
  >
    {children}
  </Tooltip>
);

export default UserInfoTooltip;
