import styled from '@emotion/styled';
import { FC } from 'react';
import { useResizedImage } from '../../hooks';
import placeholder from '../../assets/image-placeholder-h52.jpg';
import _ from 'lodash';
import { useModalProvider } from '../ModalProvider';
import PreviewImageModal, { PreviewImageModalProps } from './PreviewImageModal';

export interface ThumbnailProps {
  thumb: string;
}

const ThumbImage = styled.img({
  maxHeight: '52px',
  height: 'auto',
  width: 'auto',
  padding: '0',
  margin: '0',
  aspectRatio: '16 / 9',
  objectFit: 'contain',
  cursor: 'pointer',
});

const Thumbnail: FC<ThumbnailProps> = ({ thumb }) => {
  const uri = useResizedImage(thumb);
  const { openModal, closeModal } = useModalProvider();

  const handleClosePreview = () => {
    closeModal({
      identifier: 'PreviewImageModal',
    });
  };

  const handleOpenPreview = () => {
    openModal<PreviewImageModalProps>({
      identifier: 'PreviewImageModal',
      component: PreviewImageModal,
      additionalProps: {
        imageId: thumb,
        onClose: handleClosePreview,
      },
    });
  };

  return (
    <ThumbImage
      alt="Contenuto campagna"
      src={_.isNil(uri) ? placeholder : uri}
      title="Contenuto campagna"
      onClick={handleOpenPreview}
    />
  );
};

export default Thumbnail;
