import styled from '@emotion/styled';
import { Box, Icon, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useState } from 'react';
import { VideoCampaignTimeSlot } from '../../apis/videoApis';
import { CityAdminVideoTimeSlotGrouped } from './CampaignsTableRow';
import TimeSlot from './TimeSlot';

export interface TimeSlotsProps {
  slots: Array<VideoCampaignTimeSlot>;
}

const slotGrouping = (
  slots: Array<VideoCampaignTimeSlot>
): CityAdminVideoTimeSlotGrouped[] => {
  const groups = _.groupBy(slots, (s) => s.from_date + '-' + s.to_date);

  return _.map(groups, (g) => ({
    id: _.head(g)?.from_date + '-' + _.head(g)?.to_date,
    site_id: _.head(g)?.site_id,
    from_date: _.head(g)?.from_date,
    to_date: _.head(g)?.to_date,
    timeSlots: _.map(g, (x) => x.slot_time),
  }));
};

const TimeSlotsList = styled.ul({
  listStyle: 'none',
  listStyleYype: 'none',
  margin: '0',
  marginTop: '8px',
  padding: '0',
  li: {
    marginLeft: '6px',
    display: 'inline-block',
  },
  'li:first-of-type': {
    paddingTop: '8px',
  },
});

export const TimeSlotsContent: FC<TimeSlotsProps> = ({ slots }) => (
  <TimeSlotsList>
    {_.map(slotGrouping(slots), (s) => (
      <li>
        <TimeSlot slot={s} key={s.id} />
      </li>
    ))}
  </TimeSlotsList>
);

const TimeSlots: FC<TimeSlotsProps> = ({ slots }) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);

  return (
    <>
      <Box mr="8px" display={'inline'}>
        <Text as="span">{slots.length}</Text>
      </Box>
      <Icon
        icon={showDetail ? 'angle-up' : 'angle-down'}
        onClick={() => setShowDetail(!showDetail)}
        style={{ cursor: 'pointer' }}
      />
      {showDetail && <TimeSlotsContent slots={slots} />}
    </>
  );
};

export default TimeSlots;
