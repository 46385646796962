import _ from 'lodash';
import { UserInfo } from '../apis/userApis';
import { Roles } from '../enums';

export const isUserRoleCityAdmin = (user: UserInfo) => {
  return !_.isNil(user) && _.some(user.roles, (x) => x === Roles.SUPER_ADMIN);
};

export const isUserRoleAdvisor = (user: UserInfo) => {
  return !_.isNil(user) && _.some(user.roles, (x) => x === Roles.ADVISOR);
};
