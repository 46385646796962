import { FC } from 'react';
import { Feedback } from '../../apis/feedbackApis';
import { formatDate, formatTime } from '../../utils/dateHelper';
import {
  DissatisfiedSentimentIcon,
  NeutralSentimentIcon,
  SatisfiedSentimentIcon,
} from './Icons';
import UserInfoTooltip from './UserInfoTooltip';

export interface FeedbackRowProps {
  feedback: Feedback;
}

const evaluateSentiment = (feedback: string) => {
  switch (feedback) {
    case 'SATISFIED':
      return <SatisfiedSentimentIcon />;
    case 'NEUTRAL':
      return <NeutralSentimentIcon />;
    case 'DISSATISFIED':
      return <DissatisfiedSentimentIcon />;
  }
};

const evaluateSection = (section: string) => {
  switch (section) {
    case 'CAMPAIGN_SUCCESS_PAYMENT':
      return 'Pagamento campagna completato';
    case 'CAMPAIGN_CANCEL_PAYMENT':
      return 'Pagamento campagna fallito';
    case 'CAMPAIGN_BOOKING_TIMEOUT':
      return 'Timeout prenotazione campagna';
    case 'FREE_FEEDBACK':
      return 'Feedback libero';
  }
};

const FeedbackRow: FC<FeedbackRowProps> = ({ feedback }) => (
  <tr key={feedback.id}>
    <td>
      {formatDate(feedback.date)} {formatTime(feedback.date)}
    </td>
    <td>{evaluateSentiment(feedback.feedback)}</td>
    <td>{feedback.notes}</td>
    <td>{evaluateSection(feedback.section)}</td>
    <td>
      <UserInfoTooltip user={feedback.user}>
        {feedback.user?.name} {feedback.user?.surname}
      </UserInfoTooltip>
    </td>
    <td>{feedback.city?.name || '---'}</td>
  </tr>
);

export default FeedbackRow;
