import _ from 'lodash';
import { useEffect } from 'react';
import { getCampaignsCount } from '../actions';
import { useAppSelector } from './storeHooks';
import { useAction } from './useAction';

const useStateCounters = (forceRefreshOnMount = false) => {
  const { stateCounters } = useAppSelector((store) => ({
    stateCounters: store.video.stateCounters,
  }));
  const getVideoCampaignsCount = useAction(getCampaignsCount);

  useEffect(() => {
    if (forceRefreshOnMount) {
      getVideoCampaignsCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { error, fetched, fetching } = stateCounters;
    if (_.isNil(error) && !fetching && !fetched) {
      getVideoCampaignsCount();
    }
  }, [stateCounters, getVideoCampaignsCount]);

  return {
    stateCounters: stateCounters.data,
    forceStateCountersRefresh: getVideoCampaignsCount,
  };
};

export default useStateCounters;
