import { FC } from 'react';
import { Box, Container, Text } from '@alexpireddu/map-ui';
import { Col, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import { DefaultApplication } from '../../components';
import FeedbacksTable from './FeedbacksTable';

const Feedbacks: FC = () => (
  <DefaultApplication>
    <Helmet title="Feedbacks" />

    <Container>
      <Row className="mt-24">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box>
            <Text as="h2">Feedbacks utente</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box className="mt-24">
            <FeedbacksTable />
          </Box>
        </Col>
      </Row>
    </Container>
  </DefaultApplication>
);

export default Feedbacks;
