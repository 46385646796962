import { Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { colors } from '../../theming/theme';

const SvgIcon = styled.svg({
  color: colors.text300,
  height: '24px',
  width: '24px',
});

export const DissatisfiedSentimentIcon: FC = ({ ...rest }) => {
  return (
    <Tooltip content={<span>Insoddisfatto</span>} position="right">
      <SvgIcon viewBox="0 96 960 960" {...rest} fill={colors.danger300}>
        <path
          d="M626 523q22.5 0 38.25-15.75T680 469q0-22.5-15.75-38.25T626 415q-22.5 0-38.25 15.75T572 469q0 22.5 
      15.75 38.25T626 523Zm-292 0q22.5 0 38.25-15.75T388 469q0-22.5-15.75-38.25T334 415q-22.5 0-38.25 15.75T280 
      469q0 22.5 15.75 38.25T334 523Zm146.174 116Q413 639 358.5 676.5T278 776h53q22-42 62.173-65t87.5-23Q528 688 
      567.5 711.5T630 776h52q-25-63-79.826-100-54.826-37-122-37ZM480 976q-83 0-156-31.5T197 859q-54-54-85.5-127T80 
      576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 
      156T763 859q-54 54-127 85.5T480 976Z"
        />
      </SvgIcon>
    </Tooltip>
  );
};

export const NeutralSentimentIcon: FC = ({ ...rest }) => {
  return (
    <Tooltip content={<span>Neutrale</span>} position="right">
      <SvgIcon viewBox="0 96 960 960" {...rest} fill={colors.warning300}>
        <path
          d="M626 523q22.5 0 38.25-15.75T680 469q0-22.5-15.75-38.25T626 415q-22.5 0-38.25 15.75T572 469q0 22.5 
      15.75 38.25T626 523Zm-292 0q22.5 0 38.25-15.75T388 469q0-22.5-15.75-38.25T334 415q-22.5 0-38.25 15.75T280 
      469q0 22.5 15.75 38.25T334 523Zm20 194h253v-49H354v49Zm126 259q-83 0-156-31.5T197 859q-54-54-85.5-127T80 
      576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 
      156T763 859q-54 54-127 85.5T480 976Z"
        />
      </SvgIcon>
    </Tooltip>
  );
};

export const SatisfiedSentimentIcon: FC = ({ ...rest }) => {
  return (
    <Tooltip content={<span>Soddisfatto</span>} position="right">
      <SvgIcon viewBox="0 96 960 960" {...rest} fill={colors.success300}>
        <path
          d="M626 523q22.5 0 38.25-15.75T680 469q0-22.5-15.75-38.25T626 415q-22.5 0-38.25 15.75T572 469q0 22.5 15.75 
      38.25T626 523Zm-292 0q22.5 0 38.25-15.75T388 469q0-22.5-15.75-38.25T334 415q-22.5 0-38.25 15.75T280 469q0 22.5 
      15.75 38.25T334 523Zm146 272q66 0 121.5-35.5T682 663h-52q-23 40-63 61.5T480.5 746q-46.5 0-87-21T331 663h-53q26 
      61 81 96.5T480 795Zm0 181q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 
      176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Z"
        />
      </SvgIcon>
    </Tooltip>
  );
};
