import { Text } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { VideoTableCampaign } from '../../apis/videoApis';
import { Bullet, Thumbnail } from '../../components';
import { formatCampaignState, CampaignState } from '../../enums/CampaignState';
import { formatCurrency } from '../../utils/currencyHelper';
import {
  evaluateDurationinDays,
  formatDate,
  formatTime,
} from '../../utils/dateHelper';
import ResolveButton from './ResolveButton';
import TimeSlots from './TimeSlots';
import VerifyButton from './VerifyButton';
export interface TableRowProps {
  campaign: VideoTableCampaign;
  onReloadCampaigns: Function;
}

export interface CityAdminVideoTimeSlotGrouped {
  id: string;
  site_id?: string;
  from_date?: string;
  to_date?: string;
  timeSlots: number[];
}

const evaluateBulletVariant = (state: number) => {
  switch (state) {
    case CampaignState.ToVerify:
      return 'warning';
    case CampaignState.Approved:
      return 'success';
    case CampaignState.Completed:
      return 'success';
    case CampaignState.InProgress:
      return 'warning';
    case CampaignState.Refused:
      return 'error';
    default:
      return 'info';
  }
};

const TableRow: FC<TableRowProps> = ({ campaign, onReloadCampaigns }) => (
  <tr key={campaign.id}>
    <td>
      <Thumbnail thumb={campaign.image.id} />
    </td>
    <td>{campaign.city.name}</td>
    <td>
      <span>
        {evaluateDurationinDays(campaign.from_date, campaign.to_date)} giorni
      </span>
      <br />
      <span>
        {formatDate(campaign.from_date)} - {formatDate(campaign.to_date)}
      </span>
    </td>
    <td>
      <span>{campaign.name}</span>
    </td>
    <td>
      <span>{`${campaign.user.name} ${campaign.user.surname}`}</span>
    </td>
    <td>
      <TimeSlots slots={campaign.slots} />
    </td>
    <td>
      <span>{formatDate(campaign.reserved_at)}</span>
      <br />
      <span>Ore {formatTime(campaign.reserved_at)}</span>
    </td>
    <td>{formatCurrency(campaign.price)}</td>
    <td>
      <Bullet variant={evaluateBulletVariant(campaign.state)} mr="4px" />
      <Text as="span">{formatCampaignState(campaign.state)}</Text>
      <br />
      <span>{formatDate(campaign.state_at)}</span>
    </td>
    <td>
      {campaign.state === CampaignState.ToVerify && (
        <VerifyButton
          campaign={campaign}
          onReloadCampaigns={onReloadCampaigns}
        />
      )}
      {campaign.state === CampaignState.Refused && (
        <ResolveButton
          campaign={campaign}
          onReloadCampaigns={onReloadCampaigns}
        />
      )}
    </td>
  </tr>
);

export default TableRow;
