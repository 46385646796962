import {
  deleteDefaultCampaignImage,
  getDefaultCampaignImage,
  uploadDefaultCampaignImage,
} from '../actions';
import { useAction } from './useAction';

export const useDefaultCampaignImage = () => {
  const getDefaultCampaignImg = useAction(getDefaultCampaignImage);
  const uploadDefaultCampaignImg = useAction(uploadDefaultCampaignImage);
  const deleteDefaultCampaignImg = useAction(deleteDefaultCampaignImage);

  return {
    getDefaultCampaignImg,
    uploadDefaultCampaignImg,
    deleteDefaultCampaignImg,
  };
};
