import { Button, Card, DropDown, Icon } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useState } from 'react';
import { VideoTableCampaign } from '../../apis/videoApis';
import { useModalProvider } from '../../components';
import UploadingImageModal, {
  UploadingImageModalProps,
} from './UploadingImageModal';

export interface ResolveButtonProps {
  campaign: VideoTableCampaign;
  onReloadCampaigns: Function;
}

const ButtonStyled = styled(Button)({
  paddingLeft: '24px',
  paddingRight: '24px',
});

const ResolveButton: FC<ResolveButtonProps> = ({
  campaign,
  onReloadCampaigns,
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { openModal, closeModal } = useModalProvider();

  const handleCloseModal = (reload: boolean) => {
    closeModal({
      identifier: 'UploadingImageModal',
    });
    if (reload && _.isFunction(onReloadCampaigns)) {
      onReloadCampaigns();
    }
  };

  const handleReloadContentButton = () => {
    openModal<UploadingImageModalProps>({
      identifier: 'UploadingImageModal',
      component: UploadingImageModal,
      additionalProps: {
        campaignId: campaign.id,
        onClose: handleCloseModal,
        campaignName: campaign.name,
      },
    });
  };

  return (
    <DropDown
      open={menuOpen}
      onClick={() => setMenuOpen(!menuOpen)}
      onClickOutside={() => setMenuOpen(false)}
      trigger={
        <ButtonStyled variant="secondary" size="sm">
          <span style={{ marginRight: '8px' }}>Modifica</span>
          <Icon icon={menuOpen ? 'angle-up' : 'angle-down'} />
        </ButtonStyled>
      }
      content={
        <Card size="md">
          <span
            style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
            onClick={handleReloadContentButton}
          >
            Ricarica contenuto
          </span>
        </Card>
      }
    />
  );
};

export default ResolveButton;
