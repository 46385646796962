import { ActionCreator, bindActionCreators } from 'redux';
import { useMemo } from 'react';
import { useAppDispatch } from './storeHooks';

export const useAction = <T extends ActionCreator<any>>(
  action: T
): typeof action => {
  const dispatch = useAppDispatch();

  return useMemo(
    () => bindActionCreators(action, dispatch),
    [dispatch, action]
  );
};
