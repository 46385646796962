import { Card, Text } from '@alexpireddu/map-ui';
import ImageUpload from './ImageUpload';
import { useState, useEffect, FC } from 'react';
import { useCitySites, useDefaultCampaignImage } from '../../../hooks';
import _ from 'lodash';

const DefaultCommercial: FC = () => {
  const [image, setImage] = useState<File>();
  const { sites } = useCitySites();
  const {
    getDefaultCampaignImg,
    uploadDefaultCampaignImg,
    deleteDefaultCampaignImg,
  } = useDefaultCampaignImage();

  useEffect(() => {
    if (_.some(sites)) {
      getDefaultCampaignImg(sites[0].id).then((res2: any) => {
        if ((res2.value as Blob).size !== 0) {
          var f = new File([res2.value], 'default');
          setImage(f);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites]);

  const handleSetImage = (files: Array<File>) => {
    if (files[0] !== undefined) {
      _.forEach(sites, (s) => {
        const data = new FormData();
        data.append('image', files[0]);
        data.append('site_id', s.id);
        uploadDefaultCampaignImg(data);
      });

      setImage(files[0]);
    } else {
      _.forEach(sites, (s) => {
        deleteDefaultCampaignImg(s.id).then(() => {
          setImage(undefined);
        });
      });
    }
  };

  return (
    <Card size="xl">
      <Text as="h3" marginBottom="24px">
        Campagna commerciale
      </Text>
      <ImageUpload file={image} onDropAccepted={handleSetImage} />
    </Card>
  );
};

export default DefaultCommercial;
