import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import { Box, Card, Flex, Stack, Text } from '@alexpireddu/map-ui';
import { useAction, useAppSelector, useCityParam } from '../../hooks';
import { DefaultApplication, SettingsIcon } from '../../components';
import { ConfigureCityMenuItem } from './ConfigureCityMenuItem';
import { resetSelectedCity } from '../../actions';
import _ from 'lodash';

const PageContainer = styled.div({
  display: 'flex',
});

const LeftSideCard = styled(Card)({
  height: 'calc(100vh - 150px)',
  width: '20%',
  margin: '24px',
});

const RightSide = styled.div({
  height: 'calc(100vh - 100px)',
  overflowY: 'auto',
  width: '80%',
  padding: '24px 0',
});

const CityConfiguration: FC = () => {
  const { currentCityId } = useCityParam();
  const navigate = useNavigate();
  const { city } = useAppSelector((store) => ({
    city: store.cityDetail,
  }));

  const resetCitySelected = useAction(resetSelectedCity);

  useEffect(() => {
    if (_.isNil(city)) {
      navigate('/comuni');
    }

    return () => {
      resetCitySelected();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultApplication browserTitle="Configura">
      <PageContainer>
        <LeftSideCard size="lg">
          <Flex flexDirection={'row'} alignItems="center">
            <Box>
              <SettingsIcon />
            </Box>
            <Box ml="8px">
              <Text as="h3">Configurazioni</Text>
            </Box>
          </Flex>
          <Stack>
            <ConfigureCityMenuItem
              link={`/configura/${currentCityId}/generale`}
              label="Generalità"
            />
            <ConfigureCityMenuItem
              link={`/configura/${currentCityId}/collaboratori`}
              label="Collaboratori"
            />
            <ConfigureCityMenuItem
              link={`/configura/${currentCityId}/impianti`}
              label="Impianti"
            />
            <ConfigureCityMenuItem
              link={`/configura/${currentCityId}/default-media`}
              label="Default media"
            />
            <ConfigureCityMenuItem
              link={`/configura/${currentCityId}/pubblicazione`}
              label="Pubblicazione"
            />
          </Stack>
        </LeftSideCard>

        <RightSide>
          <Outlet />
        </RightSide>
      </PageContainer>
    </DefaultApplication>
  );
};

export default CityConfiguration;
