import { Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { colors } from '../../theming/theme';

const StyledRow = styled.tr({
  backgroundColor: colors.grey200 + '!important',
  color: colors.neutral400,
});

const NoResultsRow: FC = () => (
  <StyledRow>
    <td colSpan={11}>
      <Text as="h4" textAlign={'center'}>
        Nessun risultato
      </Text>
    </td>
  </StyledRow>
);

export default NoResultsRow;
