import { Box, Button, Card, Flex, Text, Toggle } from '@alexpireddu/map-ui';
import { ChangeEvent, FC } from 'react';
import { useFormContext } from 'react-hook-form';

export interface OnlineFormValues {
  online: boolean;
}

export const defaultOnlineFormValues = {
  online: false,
};

const CityPubblication: FC = () => {
  const {
    register,
    getValues,
    formState: { isDirty },
  } = useFormContext<OnlineFormValues>();

  const onlineField = register('online');
  const { online: onlineValue } = getValues();

  return (
    <Card>
      <Text as="h3" marginBottom={32}>
        Profilo comune
      </Text>
      <Flex marginBottom={40}>
        <Box width="200px">
          <Text as="h4">{onlineValue ? 'Online' : 'Offline'}</Text>
        </Box>
        <Box>
          <Toggle
            ref={onlineField.ref}
            name={onlineField.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onlineField.onChange(e);
            }}
            onBlur={onlineField.onBlur}
          />
        </Box>
      </Flex>

      <Flex justifyContent={'flex-end'} mt="80px">
        <Button variant="primary" disabled={!isDirty} type="submit">
          Salva profilo
        </Button>
      </Flex>
    </Card>
  );
};

export default CityPubblication;
