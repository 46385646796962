import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import _ from 'lodash';
import { PaginationResult } from '../infrastructure/dataTypes';
import { get, patch, post } from '../infrastructure/webRequest';

export type VideoCampaignTimeSlot = {
  from_date: string;
  id: string;
  site_id: string;
  slot_time: number;
  to_date: string;
};

export type VideoCampaignCity = {
  id: string;
  name: string;
};

export type VideoTableCampaign = {
  from_date: string;
  id: string;
  image: {
    id: string;
  };
  name: string;
  price: number;
  purpose: number;
  reserved_at: string;
  slots: Array<VideoCampaignTimeSlot>;
  state: number;
  state_at: string;
  to_date: string;
  city: VideoCampaignCity;
  user: {
    email: string;
    id: string;
    name: string;
    surname: string;
  };
};

export type CampaignBillingDetail = {
  address: string;
  city: string;
  fiscal_code: string;
  name: string;
  pec: string;
  postal_code: string;
  province: string;
  sdi_Code: string;
  vat_number: string;
};

export type CampaignDetail = VideoTableCampaign & {
  payment_type: string;
  is_paid: boolean;
  billing: CampaignBillingDetail;
};

export type VideoCampaignListResponse = PaginationResult<VideoTableCampaign>;

export const getBusinessCampaignsV1 = (
  pageNumber: number,
  pageSize: number,
  state: number,
  cityId: string,
  userId: string,
  sortProperty?: string,
  sortOrder?: SortType
) => {
  const additionalParams: string[] = [];
  if (!_.isNil(sortProperty) && !_.isNil(sortOrder)) {
    additionalParams.push(`&sort_by=${sortProperty}`);
    additionalParams.push(`&sort_mode=${sortOrder === 'desc' ? 2 : 1}`);
  }
  if (!_.isNil(state) && state !== -1) {
    additionalParams.push(`&state=${state}`);
  }
  if (!_.isNil(state) && state === -1) {
    additionalParams.push(`&state=200&state=210&state=220&state=230&state=240`);
  }
  if (!_.isNil(cityId) && cityId !== '') {
    additionalParams.push(`&city_id=${cityId}`);
  }
  if (!_.isNil(userId) && userId !== '') {
    additionalParams.push(`&user_id=${userId}`);
  }

  return get<VideoCampaignListResponse>(
    `/v1/admin/video/campaigns?page=${pageNumber}&size=${pageSize}${_.join(
      additionalParams,
      ''
    )}`
  );
};

export const getInstitutionalCampaignsV1 = (
  pageNumber: number,
  pageSize: number,
  cityId: string,
  userId: string,
  state: number,
  sortProperty?: string,
  sortOrder?: SortType
) => {
  const additionalParams: string[] = [];
  if (!_.isNil(sortProperty) && !_.isNil(sortOrder)) {
    additionalParams.push(`&sort_by=${sortProperty}`);
    additionalParams.push(`&sort_mode=${sortOrder === 'desc' ? 2 : 1}`);
  }
  if (!_.isNil(state) && state !== -1) {
    additionalParams.push(`&state=${state}`);
  }
  if (!_.isNil(state) && state === -1) {
    additionalParams.push(`&state=200&state=210&state=220&state=230&state=240`);
  }
  if (!_.isNil(cityId) && cityId !== '') {
    additionalParams.push(`&city_id=${cityId}`);
  }

  if (!_.isNil(userId) && userId !== '') {
    additionalParams.push(`&user_id=${userId}`);
  }

  return get<VideoCampaignListResponse>(
    `/v1/admin/video/institutionals?page=${pageNumber}&size=${pageSize}${_.join(
      additionalParams,
      ''
    )}`
  );
};

export const getCampaignDetailV1 = (campaignId: string) =>
  get<CampaignDetail>(`/v1/admin/video/campaigns/${campaignId}`);

export type CampaignStateCount = {
  count: number;
  state: number;
};

export type CampaignStateCounters = Array<CampaignStateCount>;

export const getCampaignsCountV1 = () =>
  get<CampaignStateCounters>(`/v1/admin/video/campaigns/state/count`);

export const publishVideoSettingsV1 = (cityId: string, publish: boolean) =>
  post(`/v1/admin/city/${cityId}/video/settings/publish`, { publish });

export interface VerifyCampaignRequest {
  approve: boolean;
  campaign_id: string;
}

export const verifyCampaignV1 = (request: VerifyCampaignRequest) =>
  patch('/v1/admin/video/campaign/verify', request);

export const uploadVideoImageV1 = (campaignId: string, data: FormData) =>
  patch(`/v1/admin/video/campaign/uploadimage?campaign_id=${campaignId}`, data);
