import { CityTableRow } from '../apis/cityApis';
import {
  Provinces,
  Regions,
  SiteCategories,
  SiteFormats,
} from '../apis/supportApis';
import { UserInfo } from '../apis/userApis';
import { CampaignStateCount } from '../apis/videoApis';
import { RootStateType } from './configureStore';

export interface ClientError {
  statusCode?: number;
  statusMessage?: string;
  message: string;
  stackMessage?: string;
}

export type BaseInitialState<T> = {
  data: T;
  error: ClientError | null;
  fetched: boolean;
  fetching: boolean;
};

export const BASE_INITIAL_STATE: BaseInitialState<Object> = {
  data: {},
  error: null,
  fetched: false,
  fetching: false,
};

export type UserInitialStateType = BaseInitialState<UserInfo>;

export const userInitialState: UserInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: {
    name: 'Caricamento in corso...',
    email: '----',
    id: null,
    sso_user: false,
    roles: [],
  },
};

export type CitiesInitialStateType = BaseInitialState<Array<CityTableRow>>;

export const citiesInitialState: CitiesInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: [],
};

export type CityDetailInitialStateType = CityTableRow | null;

export const cityDetailInitialState: CityDetailInitialStateType = null;

export type VideoInitialStateType = {
  stateCounters: BaseInitialState<Array<CampaignStateCount>>;
};

export const videoInitialState: VideoInitialStateType = {
  stateCounters: { ...BASE_INITIAL_STATE, data: [] },
};

export type SiteCategoriesInitialStateType = BaseInitialState<SiteCategories>;

export type ProvincesInitialStateType = BaseInitialState<Provinces>;

export type RegionsInitialStateType = BaseInitialState<Regions>;

export type FormatsInitialStateType = BaseInitialState<SiteFormats>;

export type SupportInitialStateTypes = {
  siteCategories: SiteCategoriesInitialStateType;
  provinces: ProvincesInitialStateType;
  regions: RegionsInitialStateType;
  formats: FormatsInitialStateType;
};

export const supportInitialState: SupportInitialStateTypes = {
  siteCategories: { ...BASE_INITIAL_STATE, data: [] },
  provinces: { ...BASE_INITIAL_STATE, data: [] },
  regions: { ...BASE_INITIAL_STATE, data: [] },
  formats: { ...BASE_INITIAL_STATE, data: [] },
};

export const initialState: RootStateType = {
  user: userInitialState,
  support: supportInitialState,
  cities: citiesInitialState,
  video: videoInitialState,
  cityDetail: cityDetailInitialState,
};
