import {
  NotificationManager,
  Button,
  FormControl,
  Hint,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
} from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useEffect, useState, useCallback } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { createSite, updateSite } from '../../../actions';
import { CitySite } from '../../../apis/sitesApis';
import { useAppSelector } from '../../../hooks';
import { useAction } from '../../../hooks/useAction';
import { ReloadCallbackHandler } from '../../../infrastructure/commonTypes';
import { colors } from '../../../theming/theme';
import SitePricingInput from './SitePricingInput';

export type ModalInputType = 'create' | 'update';

export interface ManageSiteModalProps {
  inputMode: ModalInputType;
  onClose: ReloadCallbackHandler;
  site?: CitySite;
  currentCityId?: string;
  sitesList: CitySite[];
  siteNumber: number;
}

export interface ManageSiteModalFormValues {
  active: string;
  formatId: string;
  latitude: string;
  longitude: string;
  name: string;
  price: string;
}

export const defaultManageSiteModalFormValues = {
  formatId: '',
  latitude: '',
  longitude: '',
  name: '',
  price: '0',
};

const evaluateLabels = (modalType: ModalInputType) => ({
  modalTitle: modalType === 'create' ? 'Crea impianto' : 'Modifica impianto',
  submitButtonText:
    modalType === 'create' ? 'Crea impianto' : 'Modifica impianto',
  successNotificationMessage:
    modalType === 'create' ? 'Impianto aggiunto.' : 'Impianto modificato.',
});

const SiteNumber = styled.span({
  border: 'solid 1px ' + colors.text400,
  borderRadius: '8px',
  padding: '0 10px',
  fontWeight: 700,
  position: 'absolute',
  right: 0,
  top: '-51px',
});

const ManageSiteModal: FC<ManageSiteModalProps> = ({
  inputMode,
  onClose,
  site,
  currentCityId,
  siteNumber,
  sitesList,
}) => {
  const siteUpdate = useAction(updateSite);
  const siteCreate = useAction(createSite);
  const { formats } = useAppSelector((store) => ({
    formats: store.support.formats,
  }));

  const { modalTitle, submitButtonText, successNotificationMessage } =
    evaluateLabels(inputMode);

  const defaultCreateValues = {
    ...defaultManageSiteModalFormValues,
  };
  const defaultEditValues = {
    active: site?.active ? '1' : '0',
    formatId: site?.format.id,
    latitude: site?.latitude,
    longitude: site?.longitude,
    name: site?.name,
    price: site?.price.toString(),
  };

  const [loading, setLoading] = useState<boolean>(false);
  const useFormMethods = useForm<ManageSiteModalFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues:
      inputMode === 'create' ? defaultCreateValues : defaultEditValues,
  });
  const { handleSubmit, register, formState, setValue, control } =
    useFormMethods;
  const {
    errors: {
      active: activeFieldError,
      latitude: latitudeFieldError,
      longitude: longitudeFieldError,
      name: nameFieldError,
      price: priceFieldError,
    },
  } = formState;

  const handleOnSubmit: SubmitHandler<ManageSiteModalFormValues> = useCallback(
    (data: ManageSiteModalFormValues): void => {
      setLoading(true);

      switch (inputMode) {
        case 'create': {
          siteCreate(currentCityId!, {
            format_id: data.formatId,
            latitude: data.latitude,
            longitude: data.longitude,
            name: data.name,
            price: parseFloat(data.price),
          })
            .then(() => {
              setLoading(false);

              NotificationManager.notify({
                type: 'success',
                message: successNotificationMessage,
              });

              onClose(true);
            })
            .catch(() => {
              setLoading(false);
            });

          break;
        }
        case 'update': {
          siteUpdate(currentCityId!, {
            active: data.active === '1',
            format_id: data.formatId,
            id: site!.id,
            latitude: data.latitude,
            longitude: data.longitude,
            name: data.name,
            price: parseFloat(data.price),
          })
            .then(() => {
              setLoading(false);

              NotificationManager.notify({
                type: 'success',
                message: successNotificationMessage,
              });

              onClose(true);
            })
            .catch(() => {
              setLoading(false);
            });

          break;
        }
        default:
          break;
      }
    },
    [
      siteCreate,
      currentCityId,
      inputMode,
      onClose,
      site,
      siteUpdate,
      successNotificationMessage,
    ]
  );

  useEffect(() => {
    if (_.some(formats.data)) {
      setValue('formatId', formats.data[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal size="lg">
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <SiteNumber>{siteNumber}</SiteNumber>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl isInvalid={!_.isNil(nameFieldError)}>
                      <Label>Nome impianto</Label>
                      <Input
                        isFullWidth
                        size="sm"
                        {...register('name', {
                          required: 'Il campo è obbligatorio',
                          validate: (val) =>
                            !_.some(
                              sitesList,
                              (x) => x.name === val && x.id !== site?.id
                            ) || 'Esiste già un impianto con questo nome',
                        })}
                        disabled={loading}
                      />
                      <Hint invalidMessage={nameFieldError?.message} />
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={inputMode === 'update' ? 5 : 6}
                    sm={inputMode === 'update' ? 5 : 6}
                    md={inputMode === 'update' ? 5 : 6}
                    lg={inputMode === 'update' ? 5 : 6}
                    xl={inputMode === 'update' ? 5 : 6}
                  >
                    <FormControl isInvalid={!_.isNil(latitudeFieldError)}>
                      <Label>Latitudine</Label>
                      <Input
                        isFullWidth
                        size="sm"
                        {...register('latitude', {
                          required: 'Il campo è obbligatorio',
                        })}
                        disabled={loading}
                      />
                      <Hint invalidMessage={latitudeFieldError?.message} />
                    </FormControl>
                  </Col>
                  <Col
                    xs={inputMode === 'update' ? 4 : 6}
                    sm={inputMode === 'update' ? 4 : 6}
                    md={inputMode === 'update' ? 4 : 6}
                    lg={inputMode === 'update' ? 4 : 6}
                    xl={inputMode === 'update' ? 4 : 6}
                  >
                    <FormControl isInvalid={!_.isNil(longitudeFieldError)}>
                      <Label>Longitudine</Label>
                      <Input
                        isFullWidth
                        size="sm"
                        {...register('longitude', {
                          required: 'Il campo è obbligatorio',
                        })}
                        disabled={loading}
                      />
                      <Hint invalidMessage={longitudeFieldError?.message} />
                    </FormControl>
                  </Col>

                  {inputMode === 'update' && (
                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                      <FormControl isInvalid={!_.isNil(activeFieldError)}>
                        <Label>Attivo</Label>
                        <Controller
                          control={control}
                          name="active"
                          rules={{ required: 'Il campo è obbligatorio.' }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              size="sm"
                              isFullWidth
                              placeholder="Attivo"
                              isDisabled={loading}
                              options={[
                                { value: '0', label: 'No' },
                                { value: '1', label: 'Si' },
                              ]}
                            />
                          )}
                        />
                        <Hint invalidMessage={activeFieldError?.message} />
                      </FormControl>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl isInvalid={!_.isNil(priceFieldError)}>
                      <Label>Tariffa</Label>
                      <SitePricingInput
                        isFullWidth
                        size="lg"
                        {...register('price', {
                          pattern: {
                            value: /^([1-9]\d*)(,\d{2})?/,
                            message: 'Il valore deve essere superiore a zero',
                          },
                          required: 'Il campo è obbligatorio',
                        })}
                        disabled={loading}
                      />
                      <Hint invalidMessage={priceFieldError?.message} />
                    </FormControl>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => onClose(false)}
            disabled={loading}
          >
            Annulla
          </Button>
          <Button variant="primary" disabled={loading} type="submit">
            {submitButtonText}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ManageSiteModal;
