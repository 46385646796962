import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import { createCityV1, getCitiesV1 } from '../apis';
import {
  CityTableRow,
  CreateCityRequest,
  deleteDefaultInstitutionalImageV1,
  getCityInfoV1,
  getCityUsersV1,
  getDefaultInstitutionalImageV1,
  getPagedCitiesV1,
  uploadDefaultInstitutionalImageV1,
} from '../apis/cityApis';
import { actionFactory } from '../infrastructure/actionFactory';

export const getPagedCities = (
  pageNumber: number,
  pageSize: number,
  sortProperty?: string,
  sortOrder?: SortType
) =>
  actionFactory(
    'PAGED_CITIES_GET',
    getPagedCitiesV1(pageNumber, pageSize, sortProperty, sortOrder)
  );

export const getCities = () => actionFactory('CITIES_GET', getCitiesV1());

export const createCity = (city: CreateCityRequest) =>
  actionFactory('CITY_CREATE', createCityV1(city));

export const setSelectedCity = (city: CityTableRow) =>
  actionFactory('CITY_DETAIL_SELECTED', city);

export const resetSelectedCity = () => actionFactory('CITY_DETAIL_RESET');

export const getCityInfo = (cityId: string) =>
  actionFactory('CITY_INFO_GET', getCityInfoV1(cityId));

export const getCityUsers = (cityId: string) =>
  actionFactory('CITY_USERS_GET', getCityUsersV1(cityId));

export const deleteDefaultInstitutionalImage = (cityId: string) =>
  actionFactory(
    'DEFAULT_INSTITUTIONAL_DELETE',
    deleteDefaultInstitutionalImageV1(cityId)
  );

export const getDefaultInstitutionalImage = (cityId: string) =>
  actionFactory(
    'DEFAULT_INSTITUTIONAL_GET',
    getDefaultInstitutionalImageV1(cityId)
  );

export const uploadDefaultInstitutionalImage = (
  cityId: string,
  data: FormData
) =>
  actionFactory(
    'DEFAULT_INSTITUTIONAL_UPLOAD',
    uploadDefaultInstitutionalImageV1(cityId, data)
  );
