import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import { Reducer } from '@reduxjs/toolkit';
import {
  cityDetailInitialState,
  CityDetailInitialStateType,
} from '../store/initialState';
import { PublistaAction } from '../infrastructure/commonTypes';

export const cityDetailReducer: Reducer<
  CityDetailInitialStateType,
  PublistaAction
> = typeToReducer<CityDetailInitialStateType, PublistaAction>(
  {
    CITY_DETAIL_SELECTED: (state, action) => {
      return update(state, {
        $set: action?.payload,
      });
    },
    CITY_DETAIL_RESET: (state) => {
      return update(state, { $set: null });
    },
    CITY_INFO_GET_FULFILLED: (state, action) => {
      return update(state, {
        $set: action?.payload.data,
      });
    },
  },
  cityDetailInitialState
);
