import _ from 'lodash';
import { FC, useEffect, useState, useCallback } from 'react';
import {
  HeaderCell,
  useAdvancedTable,
  TableBody,
  TableHeader,
  AdvancedTable,
} from '@alexpireddu/map-ui';
import { useAction } from '../../hooks';
import { VideoCampaignListResponse } from '../../apis/videoApis';
import { getPagedBusinessCampaigns } from '../../actions/videoActions';
import TableRow from './TableRow';
import NoResultsRow from './NoResultsRow';
import { CampaignState } from '../../enums';
import useStateCounters from '../../hooks/useStateCounters';

export interface CampaignsTableProps {
  filteredState: number;
}

const CampaignsTable: FC<CampaignsTableProps> = ({ filteredState }) => {
  const [campaignList, setCampaignList] = useState<VideoCampaignListResponse>();
  const getVideoCampaigns = useAction(getPagedBusinessCampaigns);
  const { forceStateCountersRefresh } = useStateCounters();
  const advancedTableProps = useAdvancedTable({
    sortProperty: 'from_date',
    sortOrder: 'asc',
    totalCount: campaignList?.total || 0,
    pageSize: 5,
  });

  const { currentPage, pageSize, sortProperty, sortOrder } = advancedTableProps;

  const loadCampaigns = useCallback(() => {
    getVideoCampaigns(
      currentPage,
      pageSize,
      filteredState,
      '',
      '',
      sortProperty,
      sortOrder
    ).then((resp: any) => setCampaignList(resp.value.data));
  }, [
    getVideoCampaigns,
    currentPage,
    pageSize,
    filteredState,
    sortProperty,
    sortOrder,
  ]);

  const handleCampaignsReload = useCallback(() => {
    loadCampaigns();
    forceStateCountersRefresh();
  }, [loadCampaigns, forceStateCountersRefresh]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  return (
    <AdvancedTable isFullWidth {...advancedTableProps}>
      <TableHeader>
        <tr>
          <HeaderCell>Contenuto</HeaderCell>
          <HeaderCell bindingProperty="city" style={{ minWidth: '150px' }}>
            Nome comune
          </HeaderCell>
          <HeaderCell bindingProperty="from_date">Periodo</HeaderCell>
          <HeaderCell bindingProperty="name">Nome campagna</HeaderCell>
          <HeaderCell>Richiedente</HeaderCell>
          <HeaderCell style={{ minWidth: '170px' }}>Spazi</HeaderCell>
          <HeaderCell>Prenotazione</HeaderCell>
          <HeaderCell bindingProperty="price" style={{ minWidth: '110px' }}>
            Imposta
          </HeaderCell>
          <HeaderCell bindingProperty="state_at">Stato</HeaderCell>
          {filteredState === CampaignState.ToVerify && (
            <HeaderCell>Accetta/Respingi</HeaderCell>
          )}
          {filteredState === CampaignState.Refused && (
            <HeaderCell style={{ minWidth: '150px' }}>Risolvi</HeaderCell>
          )}
        </tr>
      </TableHeader>
      <TableBody>
        {_.map(campaignList?.results, (c) => (
          <TableRow
            key={c.id}
            campaign={c}
            onReloadCampaigns={handleCampaignsReload}
          />
        ))}
        {!_.some(campaignList?.results) && <NoResultsRow />}
      </TableBody>
    </AdvancedTable>
  );
};

export default CampaignsTable;
