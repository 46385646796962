import { Button } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useCallback } from 'react';
import { NewCityIcon } from '../Icons';
import { useModalProvider } from '../ModalProvider';
import { CreateCityModalProps } from './NewCityModal';
import NewCityModal from './NewCityModal';

const ButtonStyled = styled(Button)({
  paddingLeft: '48px',
  paddingRight: '48px',
  textTransform: 'uppercase',
});

const NewCityButton: FC = () => {
  const { openModal } = useModalProvider();

  const handleOpenEditModal = useCallback(() => {
    openModal<CreateCityModalProps>({
      //lazyImportFn: () => import('./NewCityModal'),
      component: NewCityModal,
    });
  }, [openModal]);

  return (
    <>
      <ButtonStyled variant="primary" onClick={handleOpenEditModal}>
        <NewCityIcon />
        Nuovo comune
      </ButtonStyled>
    </>
  );
};

export default NewCityButton;
