import { combineReducers } from '@reduxjs/toolkit';
import { citiesReducer } from './citiesReducer';
import { cityDetailReducer } from './cityDetailReducer';
import { supportReducer } from './supportReducer';
import { userReducer } from './userReducer';
import { videoReducer } from './videoReducer';

export const combinedReducer = combineReducers({
  cities: citiesReducer,
  support: supportReducer,
  user: userReducer,
  video: videoReducer,
  cityDetail: cityDetailReducer,
});
