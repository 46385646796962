import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import { Reducer } from '@reduxjs/toolkit';
import {
  supportInitialState,
  SupportInitialStateTypes,
} from '../store/initialState';
import { parseAxiosError } from '../utils/axiosResponseHelper';
import { PublistaAction } from '../infrastructure/commonTypes';

export const supportReducer: Reducer<SupportInitialStateTypes, PublistaAction> =
  typeToReducer<SupportInitialStateTypes, PublistaAction>(
    {
      SITE_CATEGORIES_GET: {
        PENDING: (state: SupportInitialStateTypes) =>
          update(state, {
            siteCategories: {
              fetching: { $set: true },
            },
          }),
        REJECTED: (state: SupportInitialStateTypes, action: any) => {
          const payload = action?.payload;

          return update(state, {
            siteCategories: {
              fetching: { $set: false },
              fetched: { $set: false },
              error: { $set: parseAxiosError(payload) },
            },
          });
        },
        FULFILLED: (state: SupportInitialStateTypes, action: any) => {
          const payload = action?.payload;

          return update(state, {
            siteCategories: {
              fetching: { $set: false },
              fetched: { $set: true },
              data: { $set: payload.data },
              error: { $set: null },
            },
          });
        },
      },
      PROVINCES_GET: {
        PENDING: (state: SupportInitialStateTypes) =>
          update(state, {
            provinces: {
              fetching: { $set: true },
            },
          }),
        REJECTED: (state: SupportInitialStateTypes, action: any) => {
          const payload = action?.payload;

          return update(state, {
            provinces: {
              fetching: { $set: false },
              fetched: { $set: false },
              error: { $set: parseAxiosError(payload) },
            },
          });
        },
        FULFILLED: (state: SupportInitialStateTypes, action: any) => {
          const payload = action?.payload;

          return update(state, {
            provinces: {
              fetching: { $set: false },
              fetched: { $set: true },
              data: { $set: payload.data },
              error: { $set: null },
            },
          });
        },
      },
      REGIONS_GET: {
        PENDING: (state: SupportInitialStateTypes) =>
          update(state, {
            regions: {
              fetching: { $set: true },
            },
          }),
        REJECTED: (state: SupportInitialStateTypes, action: any) => {
          const payload = action?.payload;

          return update(state, {
            regions: {
              fetching: { $set: false },
              fetched: { $set: false },
              error: { $set: parseAxiosError(payload) },
            },
          });
        },
        FULFILLED: (state: SupportInitialStateTypes, action: any) => {
          const payload = action?.payload;

          return update(state, {
            regions: {
              fetching: { $set: false },
              fetched: { $set: true },
              data: { $set: payload.data },
              error: { $set: null },
            },
          });
        },
      },
      FORMATS_GET: {
        PENDING: (state: SupportInitialStateTypes) =>
          update(state, {
            formats: {
              fetching: { $set: true },
            },
          }),
        REJECTED: (state: SupportInitialStateTypes, action: any) => {
          const payload = action?.payload;

          return update(state, {
            formats: {
              fetching: { $set: false },
              fetched: { $set: false },
              error: { $set: parseAxiosError(payload) },
            },
          });
        },
        FULFILLED: (state: SupportInitialStateTypes, action: any) => {
          const payload = action?.payload;

          return update(state, {
            formats: {
              fetching: { $set: false },
              fetched: { $set: true },
              data: { $set: payload.data },
              error: { $set: null },
            },
          });
        },
      },
    },
    supportInitialState
  );
