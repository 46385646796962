import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { useOriginalImage } from '../../hooks';
import placeholder from '../../assets/image-placeholder-w720.jpg';
import _ from 'lodash';

export interface PreviewImageModalProps {
  imageId: string;
  onClose: Function;
}

const PreviewImage = styled.img({
  maxWidth: '720px',
  padding: '0',
  margin: '0',
  aspectRatio: '16 / 9',
  objectFit: 'contain',
});

interface PreviewProps {
  imageId: string;
}

const Preview: FC<PreviewProps> = ({ imageId }: PreviewProps) => {
  const uri = useOriginalImage(imageId);

  if (_.isNil(uri)) {
    return (
      <PreviewImage
        alt="Contenuto campagna"
        src={placeholder}
        title="Contenuto campagna"
      />
    );
  }

  return (
    <PreviewImage
      alt="Contenuto campagna"
      src={uri}
      title="Contenuto campagna"
    />
  );
};

const PreviewImageModal: FC<PreviewImageModalProps> = ({
  onClose,
  imageId,
}) => (
  <Modal size="xl" onClickOutside={() => onClose()}>
    <ModalHeader>
      <ModalCloseButton onClick={() => onClose()} />
    </ModalHeader>
    <ModalBody>
      <Preview imageId={imageId} />
    </ModalBody>
  </Modal>
);

export default PreviewImageModal;
