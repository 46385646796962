import { FC, useState } from 'react';
import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@alexpireddu/map-ui';
import ImageUpload from './ImageUpload';
import { useAction } from '../../hooks';
import { uploadVideoImage } from '../../actions';
import _ from 'lodash';
import { useModalProvider } from '../../components';

export interface ConfirmUploadModalProps {
  onConfirm: Function;
  onClose: Function;
}

const ConfirmUploadModal: FC<ConfirmUploadModalProps> = ({
  onClose,
  onConfirm,
}) => (
  <Modal>
    <ModalHeader>Conferma caricamento</ModalHeader>
    <ModalBody>
      <p>
        Sei sicuro di voler caricare questa immagine?
        <br />
        La campagna passerà direttamente in stato approvata.
      </p>
    </ModalBody>
    <ModalFooter>
      <Button variant="secondary" onClick={() => onClose()}>
        Annulla
      </Button>
      <Button variant="primary" onClick={() => onConfirm()}>
        Conferma
      </Button>
    </ModalFooter>
  </Modal>
);

export interface UploadingImageModalProps {
  campaignId: string;
  campaignName: string;
  onClose: (reload: boolean) => void;
}

const UploadingImageModal: FC<UploadingImageModalProps> = ({
  onClose,
  campaignId,
  campaignName,
}) => {
  const [image, setImage] = useState<File>();
  const uploadImage = useAction(uploadVideoImage);
  const { openModal, closeModal } = useModalProvider();

  const handleReload = () => {
    closeModal({ identifier: 'ConfirmUploadModal' });
    var data = new FormData();
    data.append('image', image!);

    uploadImage(campaignId, data).then(() => {
      onClose(true);
    });
  };

  const handleCloseConfirmModal = () => {
    closeModal({ identifier: 'ConfirmUploadModal' });
  };

  const handleOpenConfirmModal = () => {
    openModal<ConfirmUploadModalProps>({
      identifier: 'ConfirmUploadModal',
      component: ConfirmUploadModal,
      additionalProps: {
        onConfirm: handleReload,
        onClose: handleCloseConfirmModal,
      },
    });
  };

  return (
    <Modal size="xl">
      <ModalHeader>Ricarica contenuto campagna "{campaignName}"</ModalHeader>
      <ModalBody>
        <ImageUpload
          file={image}
          onDropAccepted={(files: Array<File>) => {
            setImage(files[0]);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => onClose(false)}>
          Annulla
        </Button>
        <Button
          variant="primary"
          disabled={_.isNil(image)}
          onClick={handleOpenConfirmModal}
        >
          Conferma
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadingImageModal;
