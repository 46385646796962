import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import _ from 'lodash';
import { PaginationResult } from '../infrastructure/dataTypes';
import { get, post, put, remove } from '../infrastructure/webRequest';
import { Province } from './supportApis';
import { UserRole } from './userApis';

export type CityServices = {
  paper: boolean;
  video: boolean;
};

export type CityInfo = {
  city_class: number;
  id: string;
  name: string;
  services: CityServices;
  province: Province;
};

export type CityTableRow = CityInfo & {
  approved: number;
  completed: number;
  in_progress: number;
};

export type Cities = Array<CityTableRow>;

export type CitiesPaginated = PaginationResult<CityTableRow>;

export const getPagedCitiesV1 = (
  pageNumber: number,
  pageSize: number,
  sortProperty?: string,
  sortOrder?: SortType
) => {
  const additionalParams: string[] = [];
  if (!_.isNil(sortProperty) && !_.isNil(sortOrder)) {
    additionalParams.push(`&sort_by=${sortProperty}`);
    additionalParams.push(`&sort_mode=${sortOrder === 'desc' ? 2 : 1}`);
  }

  return get<CitiesPaginated>(
    `/v1/admin/cities?page=${pageNumber}&size=${pageSize}${_.join(
      additionalParams,
      ''
    )}`
  );
};

export const getCitiesV1 = () => {
  return get<Cities>(`/v1/admin/cities`);
};

export interface CreateCityRequest {
  name: string;
  province_id: string;
}

export const createCityV1 = (city: CreateCityRequest) =>
  post('/v1/admin/city', city);

export const getCityInfoV1 = (cityId: string) =>
  get<CityInfo>(`/v1/admin/city/${cityId}/info`);

export interface CityUserRole {
  role: UserRole;
}

export type CityUserRoles = Array<CityUserRole>;

export interface CityUser {
  email: string;
  id: string;
  name: string;
  surname: string;
}

export interface CityUserInfo {
  roles: CityUserRoles;
  user: CityUser;
}

export const getCityUsersV1 = (cityId: string) =>
  get<Array<CityUserInfo>>(`/v1/admin/city/${cityId}/users`);

export const getCityLogoV1 = (cityId: string) =>
  get<string>(`/v1/admin/city/${cityId}/logo`, undefined, {
    responseType: 'arraybuffer',
    responseEncoding: 'base64',
  }).then((resp) => new Blob([resp.data]));

export const uploadCityLogoV1 = (cityId: string, data: FormData) =>
  put(`/v1/admin/city/${cityId}/logo`, data);

export const uploadCityRulesV1 = (cityId: string, data: FormData) =>
  put(`/v1/admin/city/${cityId}/regolamento`, data);

export const deleteDefaultInstitutionalImageV1 = (cityId: string) =>
  remove(`/v1/admin/city/${cityId}/video/institutionals/default`);

export const getDefaultInstitutionalImageV1 = (cityId: string) =>
  get<string>(
    `/v1/admin/city/${cityId}/video/institutionals/default`,
    undefined,
    {
      responseType: 'arraybuffer',
      responseEncoding: 'base64',
    }
  ).then((resp) => new Blob([resp.data]));

export const uploadDefaultInstitutionalImageV1 = (
  cityId: string,
  data: FormData
) => put(`/v1/admin/city/${cityId}/video/institutionals/default`, data);
