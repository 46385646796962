import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import { Reducer } from '@reduxjs/toolkit';
import { userInitialState, UserInitialStateType } from '../store/initialState';
import { parseAxiosError } from '../utils/axiosResponseHelper';
import { PublistaAction } from '../infrastructure/commonTypes';

export const userReducer: Reducer<UserInitialStateType, PublistaAction> =
  typeToReducer<UserInitialStateType, PublistaAction>(
    {
      SUBSCRIBER_INFO_GET: {
        PENDING: (state) =>
          update(state, {
            fetching: { $set: true },
          }),
        REJECTED: (state, action) => {
          const payload = action?.payload;

          return update(state, {
            fetching: { $set: false },
            fetched: { $set: false },
            error: { $set: parseAxiosError(payload) },
          });
        },
        FULFILLED: (state, action) => {
          const payload = action?.payload;

          return update(state, {
            fetching: { $set: false },
            fetched: { $set: true },
            data: {
              $merge: payload.data,
            },
            error: { $set: null },
          });
        },
      },
      SUBSCRIBER_ROLES_GET: {
        PENDING: (state) =>
          update(state, {
            fetching: { $set: true },
          }),
        REJECTED: (state, action) => {
          const payload = action?.payload;

          return update(state, {
            fetching: { $set: false },
            fetched: { $set: false },
            error: { $set: parseAxiosError(payload) },
          });
        },
        FULFILLED: (state, action) => {
          const payload = action?.payload;

          return update(state, {
            fetching: { $set: false },
            fetched: { $set: true },
            data: {
              roles: { $set: payload.data },
            },
            error: { $set: null },
          });
        },
      },
    },
    userInitialState
  );
