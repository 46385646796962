import {
  DEV_AUTH_REDIRECT_URI,
  DEV_BACKEND_URI,
  DEV_HOMEPAGE_URI,
  PROD_AUTH_REDIRECT_URI,
  PROD_BACKEND_URI,
  PROD_HOMEPAGE_URI,
  PROD_LEDWALL_URI,
  TEST_AUTH_REDIRECT_URI,
  TEST_BACKEND_URI,
  TEST_HOMEPAGE_URI,
  TEST_LEDWALL_URI,
} from './constants';

export const isTestMode = (): boolean =>
  window.location.href.indexOf('qa.publista.it') !== -1;

export const isDevMode = (): boolean =>
  window.location.hostname === 'localhost';

export const isProdMode = (): boolean => !isTestMode() && !isDevMode();

export const getAuthRedirectUri = () => {
  if (isDevMode()) {
    return DEV_AUTH_REDIRECT_URI;
  }
  if (isTestMode()) {
    return TEST_AUTH_REDIRECT_URI;
  }

  return PROD_AUTH_REDIRECT_URI;
};

export const getBackendBaseUri = () => {
  if (isDevMode()) {
    return DEV_BACKEND_URI;
  }

  if (isTestMode()) {
    return TEST_BACKEND_URI;
  }

  return PROD_BACKEND_URI;
};

export const getHomepageUri = () => {
  if (isDevMode()) {
    return DEV_HOMEPAGE_URI;
  }

  if (isTestMode()) {
    return TEST_HOMEPAGE_URI;
  }

  return PROD_HOMEPAGE_URI;
};

export const getLedwallUri = () => {
  if (isDevMode()) {
    return TEST_LEDWALL_URI;
  }

  if (isTestMode()) {
    return TEST_LEDWALL_URI;
  }

  return PROD_LEDWALL_URI;
};

export const ENV_VARIABLES = {
  REACT_APP_X_API_KEY: process.env.REACT_APP_X_API_KEY,
  REACT_APP_SENTRY_API_KEY: process.env.REACT_APP_SENTRY_API_KEY,
  REFRESH_TOKEN_TIMER_TIMESPAN: 3000000,
};
