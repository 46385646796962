import { Box, Flex, Text } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { DefaultApplication } from '../../components';
import CitiesTable from './CitiesTable';
import PendingRequestsBanner from './PendingRequestsBanner';

const Cities: FC = () => (
  <DefaultApplication browserTitle="Comuni">
    <Container fluid className="pa__cities">
      <Row className="mt-24">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <PendingRequestsBanner />
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Flex>
            <Box>
              <Text as="h2" className="mb-16">
                Tutti i comuni
              </Text>
            </Box>
          </Flex>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <CitiesTable />
        </Col>
      </Row>
    </Container>
  </DefaultApplication>
);

export default Cities;
