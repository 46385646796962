import { Icon, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useState } from 'react';
import { VideoCampaignTimeSlot } from '../../apis/videoApis';
import { CityAdminVideoTimeSlotGrouped } from './TableRow';
import TimeSlot from './TimeSlot';

export interface TimeSlotsProps {
  slots: Array<VideoCampaignTimeSlot>;
}

const slotGrouping = (
  slots: Array<VideoCampaignTimeSlot>
): CityAdminVideoTimeSlotGrouped[] => {
  const groups = _.groupBy(slots, (s) => s.from_date + '-' + s.to_date);

  return _.map(groups, (g) => ({
    id: _.head(g)?.from_date + '-' + _.head(g)?.to_date,
    site_id: _.head(g)?.site_id,
    from_date: _.head(g)?.from_date,
    to_date: _.head(g)?.to_date,
    timeSlots: _.map(g, (x) => x.slot_time),
  }));
};

const TimeSlots: FC<TimeSlotsProps> = ({ slots }) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);

  return (
    <>
      <Text as="span" marginRight="4px">
        {slots.length}
      </Text>
      <Icon
        icon={showDetail ? 'angle-up' : 'angle-down'}
        onClick={() => setShowDetail(!showDetail)}
        style={{ cursor: 'pointer' }}
      />
      {showDetail && (
        <ul className="pa__timeSlotList">
          {_.map(slotGrouping(slots), (s) => (
            <li>
              <TimeSlot slot={s} key={s.id} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default TimeSlots;
