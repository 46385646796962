import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import _ from 'lodash';
import { PaginationResult } from '../infrastructure/dataTypes';
import { get } from '../infrastructure/webRequest';

export type FeedbackCity = {
  id: string;
  name: string;
};

export type FeedbackUser = {
  id: string;
  email: string;
  name: string;
  surname: string;
};

export type Feedback = {
  additional_data: string;
  city: FeedbackCity;
  date: string;
  feedback: string;
  id: string;
  notes: string;
  section: string;
  user: FeedbackUser;
};

export type FeedbackPaginated = PaginationResult<Feedback>;

export const getFeedbacksV1 = (
  pageNumber: number,
  pageSize: number,
  sortProperty?: string,
  sortOrder?: SortType,
  fromDate?: string,
  toDate?: string,
  cityId?: string,
  feedback?: string,
  section?: string
) => {
  const additionalParams: string[] = [];
  if (!_.isNil(sortProperty) && !_.isNil(sortOrder)) {
    additionalParams.push(`&sort_by=${sortProperty}`);
    additionalParams.push(`&sort_mode=${sortOrder === 'desc' ? 2 : 1}`);
  }
  if (!_.isNil(fromDate)) {
    additionalParams.push(`&from_date=${fromDate}`);
  }
  if (!_.isNil(toDate)) {
    additionalParams.push(`&to_date=${toDate}`);
  }
  if (!_.isNil(cityId)) {
    additionalParams.push(`&city_id=${cityId}`);
  }
  if (!_.isNil(feedback)) {
    additionalParams.push(`&feedback=${feedback}`);
  }
  if (!_.isNil(section)) {
    additionalParams.push(`&section=${section}`);
  }

  return get<FeedbackPaginated>(
    `/v1/admin/feedbacks?page=${pageNumber}&size=${pageSize}${_.join(
      additionalParams,
      ''
    )}`
  );
};
