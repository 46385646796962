import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useCheckAuthorization from '../../hooks/useCheckAuthorization';

const CheckAuthorization: FC = () => {
  const auth = useCheckAuthorization();

  if (!auth) {
    return <Navigate to="unauthorized" />;
  }

  return <Outlet />;
};

export default CheckAuthorization;
