import {
  createSiteV1,
  CreateCitySiteRequest,
  deleteSiteV1,
  getSitesV1,
  updateSiteV1,
  UpdateCitySiteRequest,
  deleteDefaultCampaignImageV1,
  getDefaultCampaignImageV1,
  uploadDefaultCampaignImageV1,
} from '../apis/sitesApis';
import { actionFactory } from '../infrastructure/actionFactory';

export const getSites = (cityId: string) =>
  actionFactory('ADMIN_VIDEO_SITES_GET', getSitesV1(cityId));

export const createSite = (cityId: string, request: CreateCitySiteRequest) =>
  actionFactory('ADMIN_VIDEO_SITE_CREATE', createSiteV1(cityId, request));

export const updateSite = (cityId: string, request: UpdateCitySiteRequest) =>
  actionFactory('ADMIN_VIDEO_SITE_UPDATE', updateSiteV1(cityId, request));

export const deleteSite = (cityId: string, siteId: string) =>
  actionFactory('ADMIN_VIDEO_SITE_DELETE', deleteSiteV1(cityId, siteId));

export const deleteDefaultCampaignImage = (siteId: string) =>
  actionFactory(
    'DEFAULT_CAMPAIGNS_DELETE',
    deleteDefaultCampaignImageV1(siteId)
  );

export const getDefaultCampaignImage = (siteId: string) =>
  actionFactory('DEFAULT_CAMPAIGNS_GET', getDefaultCampaignImageV1(siteId));

export const uploadDefaultCampaignImage = (data: FormData) =>
  actionFactory('DEFAULT_CAMPAIGNS_UPLOAD', uploadDefaultCampaignImageV1(data));
