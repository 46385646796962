import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getImageResizedV1 } from '../apis';
import { getCityLogoV1 } from '../apis/cityApis';
import { getOriginalImageV1 } from '../apis/imagesApis';

export const useGenericImage = (caller: Promise<Blob>) => {
  const [uri, setURI] = useState<string>();

  useEffect(() => {
    caller.then((r) => {
      if (r.size !== 0) {
        setURI(URL.createObjectURL(r));
      }
    });

    return () => {
      if (!_.isNil(uri)) {
        URL.revokeObjectURL(uri!);
      }

      return undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return uri;
};

export const useResizedImage = (imageId: string) => {
  const [uri, setURI] = useState<string>();

  useEffect(() => {
    getImageResizedV1(imageId).then((r) => {
      if (r.size !== 0) {
        setURI(URL.createObjectURL(r));
      }
    });

    return () => {
      if (!_.isNil(uri)) {
        URL.revokeObjectURL(uri!);
      }

      return undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return uri;
};

export const useOriginalImage = (imageId: string) => {
  const [uri, setURI] = useState<string>();

  useEffect(() => {
    getOriginalImageV1(imageId).then((r) => {
      if (r.size !== 0) {
        setURI(URL.createObjectURL(r));
      }
    });

    return () => {
      if (!_.isNil(uri)) {
        URL.revokeObjectURL(uri!);
      }

      return undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return uri;
};

export const useCityLogo = (cityId: string) => {
  const [uri, setURI] = useState<string>();

  useEffect(() => {
    getCityLogoV1(cityId).then((r) => {
      if (r.size !== 0) {
        setURI(URL.createObjectURL(r));
      }
    });

    return () => {
      if (!_.isNil(uri)) {
        URL.revokeObjectURL(uri!);
      }

      return undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId]);

  return uri;
};
