import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Link } from '../../components';
import { colors } from '../../theming/theme';

export interface ConfigureCityMenuItemProps {
  link: string;
  label: string;
  active?: boolean;
}

const MenulistItem = styled(Link)<{ selected: boolean }>(({ selected }) => ({
  color: colors.text400,
  padding: '4px 8px',
  margin: '0 -8px',
  ...(selected && {
    fontWeight: 'bold',
  }),
  '&:hover, &:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));

export const ConfigureCityMenuItem: FC<ConfigureCityMenuItemProps> = ({
  link,
  label,
  active = false,
}) => {
  const { pathname } = useLocation();

  return (
    <MenulistItem selected={_.startsWith(pathname, link) || active} to={link}>
      {label}
    </MenulistItem>
  );
};
