import { Box, Text } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { Bullet, Thumbnail, useModalProvider } from '../../components';
import { formatCampaignState, CampaignState } from '../../enums/CampaignState';
import {
  evaluateDurationinDays,
  formatDateLocale,
  formatTime,
} from '../../utils/dateHelper';
import TimeSlots from './TimeSlots';
import { VideoTableCampaign } from '../../apis/videoApis';
import { formatCurrency } from '../../utils/currencyHelper';
import { CampaignDetailModalProps } from './CampaignDetailModal';
import styled from '@emotion/styled';

export interface TableRowProps {
  campaign: VideoTableCampaign;
}

export interface CityAdminVideoTimeSlotGrouped {
  id: string;
  site_id?: string;
  from_date?: string;
  to_date?: string;
  timeSlots: number[];
}

const evaluateBulletVariant = (state: number) => {
  switch (state) {
    case CampaignState.Approved:
      return 'success';
    case CampaignState.Completed:
      return 'warning';
    case CampaignState.InProgress:
      return 'warning';
    default:
      return 'info';
  }
};

export interface CampaignNameDetailLinkProps {
  id: string;
  name: string;
}

const DetailLink = styled.span({
  textDecoration: 'underline',
  cursor: 'pointer',
});

const CampaignNameDetailLink: FC<CampaignNameDetailLinkProps> = ({
  id,
  name,
}) => {
  const { openModal, closeModal } = useModalProvider();

  const handleOpen = () => {
    openModal<CampaignDetailModalProps>({
      lazyImportFn: () => import('./CampaignDetailModal'),
      identifier: `CampaignDetailModal-${id}`,
      additionalProps: {
        campaignId: id,
        onClose: () => closeModal({ identifier: `CampaignDetailModal-${id}` }),
      },
    });
  };

  return (
    <span>
      <DetailLink onClick={handleOpen}>{name}</DetailLink>
    </span>
  );
};

const CampaignsTableRow: FC<TableRowProps> = ({ campaign }) => (
  <tr key={campaign.id}>
    <td>
      <Thumbnail thumb={campaign.image.id} />
    </td>
    <td>{formatDateLocale(campaign.from_date)}</td>
    <td>{formatDateLocale(campaign.to_date)}</td>
    <td>
      <span>
        {evaluateDurationinDays(campaign.from_date, campaign.to_date)} giorni
      </span>
    </td>
    <td>
      <CampaignNameDetailLink id={campaign.id} name={campaign.name} />
    </td>
    <td>
      <span>{campaign.city.name}</span>
    </td>
    <td>
      <span>{`${campaign.user.name} ${campaign.user.surname}`}</span>
    </td>
    <td>
      <TimeSlots slots={campaign.slots} />
    </td>
    <td>{formatCurrency(campaign.price)}</td>
    <td>
      <span>{formatDateLocale(campaign.reserved_at)}</span>
      <br />
      <span>Ore {formatTime(campaign.reserved_at)}</span>
    </td>
    <td>
      <Bullet variant={evaluateBulletVariant(campaign.state)} />
      <Box ml="8px" display={'inline'}>
        <Text as="span">{formatCampaignState(campaign.state)}</Text>
      </Box>
      <br />
      <span>{formatDateLocale(campaign.to_date)}</span>
    </td>
  </tr>
);

export default CampaignsTableRow;
