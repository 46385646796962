import { FC, useEffect } from 'react';
import { FormControl, Hint, Input, Label, Select } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { Col, Container, Row } from 'react-grid-system';
import { Controller, useFormContext } from 'react-hook-form';
import { getProvinces, getRegions } from '../../actions';
import { useAction, useAppSelector } from '../../hooks';
import { CreateCityFormValues } from './NewCityModal';

export interface NewCityModalFormsProps {
  isLoading: boolean;
}

const NewCityModalForms: FC<NewCityModalFormsProps> = ({ isLoading }) => {
  const { register, formState, control, getValues } =
    useFormContext<CreateCityFormValues>();
  const { regionId } = getValues();
  const {
    errors: {
      name: nameFieldError,
      regionId: regionIdFieldError,
      provinceId: provinceIdFieldError,
    },
  } = formState;
  const { regions, provinces } = useAppSelector((store) => ({
    regions: store.support.regions,
    provinces: store.support.provinces,
  }));
  const provincesGet = useAction(getProvinces);
  const regionsGet = useAction(getRegions);

  useEffect(() => {
    const { data, error } = regions;

    if (_.isNil(error) && !_.some(data)) {
      regionsGet();
    }
  }, [regions, regionsGet]);

  return (
    <Container fluid>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormControl isInvalid={!_.isNil(nameFieldError)}>
            <Label>Nome comune</Label>
            <Input
              isFullWidth
              size="sm"
              {...register('name', {
                required: 'Il campo è obbligatorio',
              })}
              disabled={isLoading}
            />
            <Hint invalidMessage={nameFieldError?.message} />
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <FormControl isInvalid={!_.isNil(regionIdFieldError)}>
            <Label>Regione</Label>
            <Controller
              control={control}
              name="regionId"
              rules={{ required: 'Il campo è obbligatorio.' }}
              render={({ field: { onChange, ...rest } }) => (
                <Select
                  size="sm"
                  isFullWidth
                  {...rest}
                  placeholder="Scegli regione"
                  onChange={(val) => {
                    onChange(val);
                    provincesGet(val as string);
                  }}
                  isDisabled={isLoading}
                  options={_.map(
                    _.sortBy(regions.data, (x) => x.name),
                    (p) => ({ value: p.id, label: p.name })
                  )}
                />
              )}
            />

            <Hint invalidMessage={regionIdFieldError?.message} />
          </FormControl>
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <FormControl isInvalid={!_.isNil(provinceIdFieldError)}>
            <Label>Provincia</Label>
            <Controller
              control={control}
              name="provinceId"
              rules={{ required: 'Il campo è obbligatorio.' }}
              render={({ field }) => (
                <Select
                  size="sm"
                  {...field}
                  isFullWidth
                  placeholder="Scegli provincia"
                  isDisabled={isLoading || _.isNil(regionId)}
                  options={_.map(
                    _.filter(provinces.data, (x) => x.region_id === regionId),
                    (p) => ({ value: p.id, label: p.name })
                  )}
                />
              )}
            />
            <Hint invalidMessage={provinceIdFieldError?.message} />
          </FormControl>
        </Col>
      </Row>
    </Container>
  );
};

export default NewCityModalForms;
