import styled from '@emotion/styled';
import { FC, SVGAttributes } from 'react';
import SvgIcon from './SvgIcon';

const PoliceSvg = styled(SvgIcon)({
  width: '40px',
  height: 'auto',
});

const PoliceIcon: FC<SVGAttributes<SVGElement>> = (props) => (
  <PoliceSvg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    width="48"
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M24 28.3Q25.85 28.3 27.15 27.025Q28.45 25.75 28.45 23.85Q28.45 22 27.15 20.7Q25.85 19.4 24 19.4Q22.15 19.4 20.85 20.7Q19.55 22 19.55 23.85Q19.55 25.75 20.85 27.025Q22.15 28.3 24 28.3ZM24 40.9Q26.35 40.1 28.8 38.375Q31.25 36.65 32.75 34.45L28.15 30.05Q27.2 30.6 26.15 30.95Q25.1 31.3 24 31.3Q20.9 31.3 18.725 29.15Q16.55 27 16.55 23.85Q16.55 20.75 18.725 18.575Q20.9 16.4 24 16.4Q27.1 16.4 29.275 18.575Q31.45 20.75 31.45 23.9Q31.45 24.95 31.15 26Q30.85 27.05 30.2 27.9L34.4 31.9Q35.6 29.75 36.3 27.175Q37 24.6 37 21.9V12L24 7.25L11 12V21.9Q11 28.45 14.625 33.725Q18.25 39 24 40.9ZM24 44Q17 42.25 12.5 35.875Q8 29.5 8 21.9V10L24 4L40 10V21.9Q40 29.5 35.5 35.875Q31 42.25 24 44ZM24 24.05Q24 24.05 24 24.05Q24 24.05 24 24.05Q24 24.05 24 24.05Q24 24.05 24 24.05Q24 24.05 24 24.05Q24 24.05 24 24.05Q24 24.05 24 24.05Q24 24.05 24 24.05Z" />
  </PoliceSvg>
);

export default PoliceIcon;
