import {
  configureSentryUser,
  resetSentryUser,
} from '../infrastructure/sentryConfiguration';
import { get, post, remove } from '../infrastructure/webRequest';

export type UserRole = string;

export type UserRoles = Array<UserRole>;

export type BaseUserInfo = {
  email: string;
  id: string | null;
  name: string;
  phone?: string;
  surname?: string;
  sso_user: boolean;
};

export const getUsersV1 = () => get<Array<BaseUserInfo>>('/v1/admin/users');

export type UserInfo = BaseUserInfo & {
  roles: UserRoles;
};

export const getSubscriberRolesV1 = () =>
  get<UserRoles>('/v1/subscriber/roles');

export const subscriberLogoutV1 = () =>
  get(`/v1/subscriber/logout`).then((resp) => {
    resetSentryUser();

    return resp;
  });

export const getSubscriberInfoV1 = () =>
  get<UserInfo>('/v1/subscriber/info').then((resp) => {
    const {
      data: { id, email, name, surname },
    } = resp;
    configureSentryUser(id!, email, name, surname);

    return resp;
  });

export const refreshTokenV1 = () => {
  get('/v1/subscriber/token/refresh');
};

export interface CreateUserRequest {
  entity_related_role_id: string;
  email: string;
  name: string;
  role: string;
  surname: string;
}

export const createUserV1 = (user: CreateUserRequest) =>
  post('/v1/admin/users', user);

export interface DeleteUserRoleRequest {
  entity_related_role_id: string;
  role: string;
}

export const deleteUserRoleV1 = (
  user_id: string,
  request: DeleteUserRoleRequest
) =>
  remove(
    `/v1/admin/users/${user_id}/roles?entity_related_role_id=${request.entity_related_role_id}&role=${request.role}`
  );

export interface CheckUserRoleRequest {
  email: string;
  role: string;
  entity_related_role_id: string;
}

export const checkUserRoleV1 = (request: CheckUserRoleRequest) =>
  get<UserInfo>(
    `/v1/admin/users/check-role?email=${request.email}&role=${request.role}&entity_related_role_id=${request.entity_related_role_id}`
  );

export interface AddRoleToUserRequest {
  entity_related_role_id: string;
  role: string;
}

export const addRoleToUserV1 = (
  user_id: string,
  request: AddRoleToUserRequest
) => post(`/v1/admin/users/${user_id}/roles`, request);
