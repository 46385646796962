import { get } from '../infrastructure/webRequest';

export const getResizedImageV1 = (imageId: string) => {
  return get<string>(`/v1/admin/image/${imageId}/resized`, undefined, {
    responseType: 'arraybuffer',
    responseEncoding: 'base64',
  }).then((resp) => new Blob([resp.data]));
};

export const getOriginalImageV1 = (imageId: string) => {
  return get<string>(`/v1/admin/image/${imageId}`, undefined, {
    responseType: 'arraybuffer',
    responseEncoding: 'base64',
  }).then((resp) => new Blob([resp.data]));
};
