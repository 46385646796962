import {
  getFormatsV1,
  getProvinceInfoV1,
  getProvincesByRegionV1 as getProvincesByRegion,
  getRegionsV1 as getAllRegions,
  getSiteCategoriesV1 as getAllSiteCategories,
} from '../apis/supportApis';
import { actionFactory } from '../infrastructure/actionFactory';

export const getProvince = (id: string) =>
  actionFactory('PROVINCE_INFO_GET', getProvinceInfoV1(id));

export const getProvinces = (regionId: string) =>
  actionFactory('PROVINCES_GET', getProvincesByRegion(regionId));

export const getRegions = () => actionFactory('REGIONS_GET', getAllRegions());

export const getSiteCategories = () =>
  actionFactory('SITE_CATEGORIES_GET', getAllSiteCategories());

export const getFormats = () => actionFactory('FORMATS_GET', getFormatsV1());
