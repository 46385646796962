import { NotificationManager } from '@alexpireddu/map-ui';
import { AxiosError } from 'axios';
import isPromise from 'is-promise';
import _ from 'lodash';
import { Dispatch, Middleware } from 'redux';
import { PublistaAction } from '../infrastructure/commonTypes';
import { extractResponseError } from '../utils/axiosResponseHelper';

const evaluateAxiosError = (actionType: string, err: AxiosError) => {
  if (_.isNil(err) || err?.response?.status === 401) {
    return;
  }

  const respError = extractResponseError(actionType, err);

  if (!_.isNil(respError)) {
    NotificationManager.notify({
      type: 'error',
      message: respError?.message,
      title: respError?.statusMessage,
      duration: 'slow',
    });
  }
};

const exceptionsMiddleware: Middleware<Dispatch<PublistaAction>> =
  () => (next: Dispatch<PublistaAction>) => (action: PublistaAction) => {
    const { payload, error, meta } = action;

    if (!_.isNil(meta) && meta.globalCatch) {
      if (!_.isNil(error)) {
        evaluateAxiosError(action.type, payload);
      }
    }

    if (!isPromise(payload)) {
      return next(action);
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    return next(action).catch((error: AxiosError) => {
      if (!_.isNil(meta) && meta.globalCatch) {
        evaluateAxiosError(action.type, error);
      }

      return error;
    });
  };

export default exceptionsMiddleware;
