import _ from 'lodash';

export const evaluateDurationinDays = (fromDate: string, toDate: string) => {
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);

  const diff = endDate.getTime() - startDate.getTime();

  return Math.ceil(diff / (1000 * 3600 * 24));
};

export const formatDate = (dateString?: string) => {
  if (_.isNil(dateString)) {
    return null;
  }

  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return dateFormatter.format(new Date(dateString));
};

export const formatDateLocale = (dateString?: string) => {
  if (_.isNil(dateString)) {
    return null;
  }

  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return dateFormatter.format(new Date(dateString));
};

export const formatTime = (dateString?: string) => {
  if (_.isNil(dateString)) {
    return null;
  }

  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return dateFormatter.format(new Date(dateString));
};

export const formatDayMonth = (dateString?: string) => {
  if (_.isNil(dateString)) {
    return null;
  }

  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    day: '2-digit',
    month: 'short',
  });

  return dateFormatter.format(new Date(dateString));
};
