import { Reducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import typeToReducer from 'type-to-reducer';
import { PublistaAction } from '../infrastructure/commonTypes';
import {
  citiesInitialState,
  CitiesInitialStateType,
} from '../store/initialState';
import { parseAxiosError } from '../utils/axiosResponseHelper';

export const citiesReducer: Reducer<CitiesInitialStateType, PublistaAction> =
  typeToReducer<CitiesInitialStateType, PublistaAction>(
    {
      PAGED_CITIES_GET: {
        PENDING: (state) =>
          update(state, {
            fetching: { $set: true },
          }),
        REJECTED: (state, action) => {
          const payload = action?.payload;

          return update(state, {
            fetching: { $set: false },
            fetched: { $set: false },
            error: { $set: parseAxiosError(payload) },
          });
        },
        FULFILLED: (state, action) => {
          const payload = action?.payload;

          return update(state, {
            fetching: { $set: false },
            fetched: { $set: true },
            data: { $set: payload.data },
            error: { $set: null },
          });
        },
      },
    },
    citiesInitialState
  );
