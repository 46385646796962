import {
  Box,
  Button,
  Flex,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useCityParam } from '../../hooks';
import { colors } from '../../theming/theme';
import { isUserRoleAdvisor, isUserRoleCityAdmin } from '../../utils/userUtils';
import { LocalPoliceIcon, PoliceIcon } from '../Icons';
import CityLogo from '../CityLogo';

const FlexStyled = styled(Flex)({
  fontSize: '44px',
  fontWeight: '700',
  alignItems: 'center',
});

const RoleButton = styled(Button)({
  '&:hover,&:active,&:focus': {
    backgroundColor: colors.white,
  },
  padding: '4px 3px 3px 3px',
  marginRight: '12px',
  borderRadius: '12px',
});

const CitySelect = styled(Select)({
  minWidth: '160px',
});

const RoleName: FC = () => {
  const { currentCityId } = useCityParam();
  const navigate = useNavigate();
  const { user, city } = useAppSelector((store) => ({
    user: store.user.data,
    city: store.cityDetail,
  }));

  const advisor = isUserRoleAdvisor(user);
  const admin = isUserRoleCityAdmin(user);

  if (!admin && !advisor) {
    return null;
  }

  return _.isNil(currentCityId) ? (
    <FlexStyled>
      <Box>
        {admin && <LocalPoliceIcon />}
        {advisor && <PoliceIcon />}
      </Box>
      <Box mb="8px">
        {admin && <Text as="h2">ADMIN</Text>}
        {advisor && <Text as="h2">ADVISOR</Text>}
      </Box>
    </FlexStyled>
  ) : (
    <div style={{ display: 'flex' }}>
      <Tooltip content={<span>Ritorna a lista comuni</span>}>
        <RoleButton variant="secondary" onClick={() => navigate('/comuni')}>
          {admin && <LocalPoliceIcon />}
          {advisor && <PoliceIcon />}
        </RoleButton>
      </Tooltip>

      <CitySelect
        value={'1'}
        isDisabled
        size="lg"
        options={[
          {
            value: '1',
            label: (
              <Stack direction="row" alignItems="center" spacing={2}>
                <CityLogo cityId={city?.id!} header />
                {city?.name}
              </Stack>
            ),
          },
        ]}
        onChange={() => {
          /**/
        }}
      />
    </div>
  );
};

export default RoleName;
