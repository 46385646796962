import styled from '@emotion/styled';
import { FC, SVGAttributes } from 'react';
import SvgIcon from './SvgIcon';

const LocalPoliceSvg = styled(SvgIcon)({
  width: '40px',
  height: 'auto',
});

const LocalPoliceIcon: FC<SVGAttributes<SVGElement>> = (props) => (
  <LocalPoliceSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M18.7 30.35 24 26.45 29.15 30.35 27 24.05 32.3 20.25H25.9L24 14L22.1 20.25H15.7L20.9 24.05ZM24 43.95Q17 42.2 12.5 35.825Q8 29.45 8 21.85V9.95L24 3.95L40 9.95V21.85Q40 29.45 35.5 35.825Q31 42.2 24 43.95ZM24 24.55Q24 24.55 24 24.55Q24 24.55 24 24.55Q24 24.55 24 24.55Q24 24.55 24 24.55ZM24 40.85Q29.75 38.95 33.375 33.675Q37 28.4 37 21.85V12.05L24 7.15L11 12.05V21.85Q11 28.4 14.625 33.675Q18.25 38.95 24 40.85Z" />
  </LocalPoliceSvg>
);

export default LocalPoliceIcon;
