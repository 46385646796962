import { Box, NotificationManager, Text } from '@alexpireddu/map-ui';
import { FC, useRef, useCallback } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { getCityInfo, publishVideoSettings } from '../../../actions';
import { useAction, useAppSelector, useCityParam } from '../../../hooks';
import CityPubblication, { OnlineFormValues } from './CityPubblication';

export interface GestionePubblicazioneFormValues extends OnlineFormValues {
  //
}

const Pubblication: FC = () => {
  const { currentCityId } = useCityParam();
  const formRef = useRef<HTMLFormElement>(null);
  const { city } = useAppSelector((store) => ({ city: store.cityDetail }));
  const useFormMethods = useForm<GestionePubblicazioneFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      online: city?.services.video,
    },
  });
  const { handleSubmit, reset } = useFormMethods;

  const publishSettings = useAction(publishVideoSettings);
  const cityInfoGet = useAction(getCityInfo);

  const handleOnSubmit: SubmitHandler<GestionePubblicazioneFormValues> =
    useCallback(
      (data): void => {
        publishSettings(currentCityId!, data.online).then(() => {
          NotificationManager.notify({
            type: 'success',
            message: 'Profilo salvato',
          });

          cityInfoGet(currentCityId!).then((resp: any) => {
            reset({ online: resp.value.data.services.video });
          });
        });
      },
      [cityInfoGet, currentCityId, reset, publishSettings]
    );

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)} ref={formRef}>
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box ml={12} mt={12} mb={24}>
                <Text as="h2">Pubblicazione</Text>
              </Box>

              <Box mb={24}>
                <CityPubblication />
              </Box>
            </Col>
          </Row>
        </Container>
      </form>
    </FormProvider>
  );
};

export default Pubblication;
