import { Box, Text } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { Bullet, Thumbnail } from '../../components';
import { formatCampaignState, CampaignState } from '../../enums/CampaignState';
import {
  evaluateDurationinDays,
  formatDateLocale,
  formatTime,
} from '../../utils/dateHelper';
import { VideoTableCampaign } from '../../apis/videoApis';

export interface TableRowProps {
  campaign: VideoTableCampaign;
}

export interface CityAdminVideoTimeSlotGrouped {
  id: string;
  site_id?: string;
  from_date?: string;
  to_date?: string;
  timeSlots: number[];
}

const evaluateBulletVariant = (state: number) => {
  switch (state) {
    case CampaignState.Approved:
      return 'success';
    case CampaignState.Completed:
      return 'warning';
    case CampaignState.InProgress:
      return 'warning';
    default:
      return 'info';
  }
};

const InstitutionalsTableRow: FC<TableRowProps> = ({ campaign }) => (
  <tr key={campaign.id}>
    <td>
      <Thumbnail thumb={campaign.image.id} />
    </td>
    <td>{formatDateLocale(campaign.from_date)}</td>
    <td>{formatDateLocale(campaign.to_date)}</td>
    <td>
      <span>
        {evaluateDurationinDays(campaign.from_date, campaign.to_date)} giorni
      </span>
    </td>
    <td>
      <span>{campaign.name}</span>
    </td>
    <td>
      <span>{campaign.city.name}</span>
    </td>
    <td>
      <span>{`${campaign.user.name} ${campaign.user.surname}`}</span>
    </td>
    <td>
      <span>{formatDateLocale(campaign.reserved_at)}</span>
      <br />
      <span>Ore {formatTime(campaign.reserved_at)}</span>
    </td>
    <td>
      <Bullet variant={evaluateBulletVariant(campaign.state)} />
      <Box ml="8px" display={'inline'}>
        <Text as="span">{formatCampaignState(campaign.state)}</Text>
      </Box>
      <br />
      <span>{formatDateLocale(campaign.to_date)}</span>
    </td>
  </tr>
);

export default InstitutionalsTableRow;
